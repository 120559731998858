import { downloadFile } from '@faceup/utils'
import { ok } from 'neverthrow'
import { Stream } from './atoms/stream'
import { readReportKey } from './report'
import { createErr, mapErr } from './utils/general'

export type FileDescriptor = {
  url: string
  name: string
  mimetype: string
}

export const loadFileWithReportKey = async (
  { url, name, mimetype }: FileDescriptor,
  recipientKey: string
) => {
  const data = await fetch(url)
  const blob = await data.blob()
  const file = new File([blob], name, { type: mimetype })

  const reportKey = await readReportKey(recipientKey)
  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when loading file')
  }

  const decryptedFile = await decryptFileWithReportKey(file, reportKey.value)
  if (decryptedFile.isErr()) {
    return mapErr(decryptedFile, 'Could not decrypt file with report key')
  }

  return ok(decryptedFile.value)
}

export const downloadFileWithReportKey = async (
  file: FileDescriptor,
  recipientKey: string,
  // Define this function if standard download using `.click()` on created element is not available (e.g. mobile app)
  download: (blob: Blob, name: string) => void = downloadFile
) => {
  const decryptedFile = await loadFileWithReportKey(file, recipientKey)
  if (decryptedFile.isErr()) {
    return mapErr(decryptedFile, 'Could not download file with report key')
  }

  return ok(download(decryptedFile.value, file.name))
}

export const decryptFileWithReportKey = async (file: File, reportKey: Uint8Array) => {
  const buffer = await file.arrayBuffer()
  const decryptedChunks = await Stream.decrypt(Stream.toStreamKey(reportKey), {
    slice: (from, to) => buffer.slice(from, to),
    size: file.size,
  })

  if (decryptedChunks.isErr()) {
    return mapErr(decryptedChunks, 'Could not decrypt file with report key')
  }

  return ok(new File(decryptedChunks.value, file.name, { type: file.type }))
}

// this function is only used on backend, `stream` is not available in browser
export const encryptBufferWithReportKey = async (fileBuffer: Buffer, reportKey: Uint8Array) => {
  const encryptedFile = await Stream.encrypt(Stream.toStreamKey(reportKey), {
    slice: (from, to) => fileBuffer.subarray(from, to),
    size: fileBuffer.byteLength,
  })

  if (encryptedFile.isErr()) {
    return mapErr(encryptedFile, 'Could not encrypt buffer with report key')
  }

  try {
    // dynamically import stream to avoid bundling it in browser
    const { Readable } = await import('node:stream')
    return ok(Readable.from(Buffer.concat(encryptedFile.value)))
  } catch (e) {
    return createErr('Could not create readable stream from encrypted buffer', e)
  }
}

export const encryptFileWithReportKey = async (file: File, reportKey: Uint8Array) => {
  const buffer = await file.arrayBuffer()
  const encryptedFile = await Stream.encrypt(Stream.toStreamKey(reportKey), {
    slice: (from, to) => buffer.slice(from, to),
    size: file.size,
  })

  if (encryptedFile.isErr()) {
    return mapErr(encryptedFile, 'Could not encrypt buffer with report key')
  }

  return ok(
    new File(encryptedFile.value, file.name, {
      type: file.type,
    })
  )
}
