import CryptoJS from 'crypto-js'
import { Result, ok } from 'neverthrow'
import { Asymmetric } from './atoms/asymmetric'
import { fromBase64, toBase64, toText } from './atoms/convertors'
import { Symmetric } from './atoms/symmetric'
import { encryptBufferWithReportKey, encryptFileWithReportKey } from './files'
import { getPersonalKeys } from './keys'
import { generatePin } from './password'
import { createErr, mapErr } from './utils/general'

type ReportBody<T> = { victimName: string | null; moreInfo: string; attachments?: T[] }

export const encryptPlaintext = async (plaintext: string, key: string) => {
  const newNonce = await Symmetric.generateNonce()
  const encryptedBody = await Symmetric.encrypt(
    plaintext,
    Symmetric.toKey(await fromBase64(key)),
    newNonce
  )

  if (encryptedBody.isErr()) {
    return mapErr(encryptedBody, 'Could not encrypt plaintext')
  }

  return ok({
    ciphertext: await toBase64(encryptedBody.value),
    nonce: await toBase64(newNonce),
  })
}

export const encryptReportData = (text: string | null, REPORT_PASSWORD: string) => {
  if (!text) {
    return null
  }
  const trimmedText = text.trim()
  if (trimmedText === '') {
    return null
  }
  return CryptoJS.AES.encrypt(trimmedText, REPORT_PASSWORD).toString()
}

export const decryptReportData = (text: string | null, REPORT_PASSWORD: string): string | null => {
  if (text) {
    const bytes = CryptoJS.AES.decrypt(text, REPORT_PASSWORD)
    return bytes.toString(CryptoJS.enc.Utf8)
  }
  return null
}
/**
 * Used mainly for creating new language variations and as a subfunction of createReport functions.
 * If you want to create new report, refer to createReportWithVictim or createReportWithoutVictim
 */
export const encryptReport = async <T>(body: ReportBody<T>, key: string) => {
  const newNonce = await Symmetric.generateNonce()
  const encryptedBody = await Symmetric.encrypt(
    JSON.stringify(body),
    Symmetric.toKey(await fromBase64(key)),
    newNonce
  )

  if (encryptedBody.isErr()) {
    return mapErr(encryptedBody, 'Cannot encrypt plaintext')
  }

  return ok({
    body: await toBase64(encryptedBody.value),
    bodyNonce: await toBase64(newNonce),
  })
}

export const createReportWithVictimWithBufferAttachments = async (
  body: ReportBody<Buffer>,
  recipients: { id: string | null; key: string }[],
  systemPbk?: string
) => {
  const pin = await generatePin()
  if (pin.isErr()) {
    return mapErr(pin, 'Could not generate pin while creating report with victim')
  }

  const { victimPayload, victimPin } = pin.value
  if (!victimPayload.publicKey) {
    return createErr('Public key missing', new Error('Public key missing'))
  }

  const key = await Symmetric.generateKey()
  const encryptedReport = await encryptReport(body, await toBase64(key))
  if (encryptedReport.isErr()) {
    return mapErr(encryptedReport, 'Could not encrypt report while creating report')
  }

  const encryptedAttachments = Result.combine(
    await Promise.all(
      body.attachments?.map(attachment => encryptBufferWithReportKey(attachment, key)) ?? []
    )
  )

  if (encryptedAttachments.isErr()) {
    return mapErr(encryptedAttachments, 'Attachment encryption failed')
  }

  const recipientKeys = await createRecipientKeys(key, recipients)
  if (recipientKeys.isErr()) {
    return mapErr(recipientKeys, 'Could not create recipient keys')
  }

  if (systemPbk) {
    const systemKey = await createSystemKey(key, systemPbk)
    if (systemKey.isErr()) {
      return mapErr(systemKey, 'Could not create system key')
    }

    recipientKeys.value.push(systemKey.value)
  }

  const senderKey = await createSenderKey(key, victimPayload.publicKey)
  if (senderKey.isErr()) {
    return mapErr(senderKey, 'Could not create sender key')
  }

  return ok({
    ...victimPayload,
    victimPin,
    body: encryptedReport.value.body,
    bodyNonce: encryptedReport.value.bodyNonce,
    recipientKeys,
    senderKey: senderKey.value,
    attachments: encryptedAttachments.value,
  })
}

export const createReportWithVictim = async (
  body: ReportBody<File>,
  recipients: { id: string | null; key: string }[],
  systemPbk?: string
) => {
  const pin = await generatePin()
  if (pin.isErr()) {
    return mapErr(pin, 'Could not generate pin while creating report with victim')
  }

  const { victimPayload, victimPin } = pin.value
  if (!victimPayload.publicKey) {
    return createErr('Public key missing', new Error('Public key missing'))
  }

  const key = await Symmetric.generateKey()
  const encryptedReport = await encryptReport(body, await toBase64(key))
  if (encryptedReport.isErr()) {
    return mapErr(encryptedReport, 'Could not encrypt report while creating report')
  }

  const encryptedAttachments = Result.combine(
    await Promise.all(
      body.attachments?.map(attachment => encryptFileWithReportKey(attachment, key)) ?? []
    )
  )

  if (encryptedAttachments.isErr()) {
    return mapErr(encryptedAttachments, 'Attachment encryption failed')
  }

  const recipientKeys = await createRecipientKeys(key, recipients)
  if (recipientKeys.isErr()) {
    return mapErr(recipientKeys, 'Could not create recipient keys')
  }

  if (systemPbk) {
    const systemKey = await createSystemKey(key, systemPbk)
    if (systemKey.isErr()) {
      return mapErr(systemKey, 'Could not create system key')
    }

    recipientKeys.value.push(systemKey.value)
  }

  const senderKey = await createSenderKey(key, victimPayload.publicKey)
  if (senderKey.isErr()) {
    return mapErr(senderKey, 'Could not create sender key')
  }

  return ok({
    ...victimPayload,
    victimPin,
    body: encryptedReport.value.body,
    bodyNonce: encryptedReport.value.bodyNonce,
    recipientKeys: recipientKeys.value,
    senderKey: senderKey.value,
    attachments: encryptedAttachments.value as File[],
  })
}

export const createReportWithoutVictim = async (
  body: ReportBody<File>,
  recipients: { id: string | null; key: string }[],
  createdKey?: string,
  systemPbk?: string
) => {
  const reportKey = createdKey ? await readReportKey(createdKey) : ok(await Symmetric.generateKey())

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not generate report key while creating report')
  }

  const encryptPayload = await encryptReport(body, await toBase64(reportKey.value))
  if (encryptPayload.isErr()) {
    return mapErr(encryptPayload, 'Could not encrypt report while creating report')
  }

  const encryptedAttachments = Result.combine(
    await Promise.all(
      body.attachments?.map(attachment => encryptFileWithReportKey(attachment, reportKey.value)) ??
        []
    )
  )

  if (encryptedAttachments.isErr()) {
    return mapErr(encryptedAttachments, 'Attachment encryption failed')
  }

  const recipientKeys = await createRecipientKeys(reportKey.value, recipients)
  if (recipientKeys.isErr()) {
    return mapErr(recipientKeys, 'Could not create recipient keys')
  }

  if (systemPbk) {
    const systemKey = await createSystemKey(reportKey.value, systemPbk)
    if (systemKey.isErr()) {
      return mapErr(systemKey, 'Could not create system key')
    }

    recipientKeys.value.push(systemKey.value)
  }

  return ok({
    body: encryptPayload.value.body,
    bodyNonce: encryptPayload.value.bodyNonce,
    recipientKeys: recipientKeys.value,
    attachments: encryptedAttachments.value as File[],
  })
}

/**
 * Without context - behaves like a pure functions, not getting keys from storage
 * Therefore can be used at BE
 */
export const repackReportKeyWithoutContext = async (
  reportKeyEncrypted: string,
  ownerPublicKey: string,
  ownerPrivateKey: string,
  recipientPublicKey: string
) => {
  const reportKey = await Asymmetric.decrypt(
    Asymmetric.toCiphertext(await fromBase64(reportKeyEncrypted)),
    Asymmetric.toPublicKey(await fromBase64(ownerPublicKey)),
    Asymmetric.toPrivateKey(await fromBase64(ownerPrivateKey))
  )

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key while repacking')
  }

  const repackedReportKey = await Asymmetric.encrypt(
    reportKey.value,
    Asymmetric.toPublicKey(await fromBase64(recipientPublicKey))
  )

  if (repackedReportKey.isErr()) {
    return mapErr(repackedReportKey, 'Could not repack report key')
  }

  return ok(await toBase64(repackedReportKey.value))
}

export const readReportKeyWithoutContext = async (
  recipientKey: string,
  recipientPublicKey: string,
  recipientPrivateKey: string
) => {
  const reportKey = await Asymmetric.decrypt(
    Asymmetric.toCiphertext(await fromBase64(recipientKey)),
    Asymmetric.toPublicKey(await fromBase64(recipientPublicKey)),
    Asymmetric.toPrivateKey(await fromBase64(recipientPrivateKey))
  )

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key')
  }

  return ok(reportKey.value)
}

export type DecryptedReport = {
  victimName: string | null
  moreInfo: string | null
}

const read = async (body: string, nonce: string, recipientKey: string) => {
  const reportKey = await readReportKey(recipientKey)

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when reading report')
  }

  const decryptedBody = await Symmetric.decrypt(
    Symmetric.toCiphertext(await fromBase64(body)),
    Symmetric.toKey(reportKey.value),
    Symmetric.toNonce(await fromBase64(nonce))
  )

  if (decryptedBody.isErr()) {
    return mapErr(decryptedBody, 'Could not read report')
  }

  return ok(JSON.parse(await toText(decryptedBody.value)))
}

export const readReport = async (body: string, nonce: string, recipientKey: string) => {
  const parsedBody = await read(body, nonce, recipientKey)

  if (parsedBody.isErr()) {
    return mapErr(parsedBody, 'Could not read report')
  }

  return ok({
    victimName: parsedBody.value.victimName ? String(parsedBody.value.victimName) : null,
    moreInfo: parsedBody.value.moreInfo ? String(parsedBody.value.moreInfo) : null,
  })
}

export const readEncryptedField = async <T extends object>(
  body: string,
  nonce: string,
  recipientKey: string
) => {
  const parsedBody = await read(body, nonce, recipientKey)

  if (parsedBody.isErr()) {
    return mapErr(parsedBody, 'Could not read encrypted field')
  }

  return ok(parsedBody?.value ? (parsedBody?.value as T) : null)
}

export const repackReportKey = async (ownerKey: string, recipientPubKey: string) => {
  const payload = await readReportKey(ownerKey)

  if (payload.isErr()) {
    return mapErr(payload, 'Could not read report key while repacking')
  }

  const repackedReportKey = await Asymmetric.encrypt(
    payload.value,
    Asymmetric.toPublicKey(await fromBase64(recipientPubKey))
  )

  if (repackedReportKey.isErr()) {
    return mapErr(repackedReportKey, 'Could not repack report key')
  }

  return ok(await toBase64(repackedReportKey.value))
}

export const readReportKey = async (recipientKey: string) => {
  const { publicKey, privateKey } = await getPersonalKeys()
  if (!publicKey || !privateKey) {
    return createErr('No public or private key', new Error('No public or private key'))
  }

  const reportKey = await Asymmetric.decrypt(
    Asymmetric.toCiphertext(await fromBase64(recipientKey)),
    Asymmetric.toPublicKey(publicKey),
    Asymmetric.toPrivateKey(privateKey)
  )

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key')
  }

  return ok(reportKey.value)
}

const createSystemKey = async (key: Uint8Array, systemPbk: string) => {
  const encryptedKeyForSystem = await Asymmetric.encrypt(
    key,
    Asymmetric.toPublicKey(await fromBase64(systemPbk))
  )

  if (encryptedKeyForSystem.isErr()) {
    return mapErr(encryptedKeyForSystem, 'Could not encrypt key for system')
  }

  return ok({
    id: null,
    key: await toBase64(encryptedKeyForSystem.value),
  })
}

const createSenderKey = async (key: Uint8Array, victimPublicKey: string) => {
  const senderKey = await Asymmetric.encrypt(
    key,
    Asymmetric.toPublicKey(await fromBase64(victimPublicKey))
  )

  if (senderKey.isErr()) {
    return mapErr(senderKey, 'Could not encrypt key for sender')
  }

  return ok(await toBase64(senderKey.value))
}

const createRecipientKeys = async <T>(key: Uint8Array, recipients: { key: string; id: T }[]) =>
  Result.combine(
    await Promise.all(
      recipients.map(async recipient => {
        const encryptedKeyForUser = await Asymmetric.encrypt(
          key,
          Asymmetric.toPublicKey(await fromBase64(recipient.key))
        )

        if (encryptedKeyForUser.isErr()) {
          return mapErr(encryptedKeyForUser, 'Could not encrypt key for user')
        }

        return ok({
          id: recipient.id,
          key: await toBase64(encryptedKeyForUser.value),
        })
      })
    )
  )
