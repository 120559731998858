import { Divider, Flex, Skeleton, Typography } from '@faceup/ui-base'
import { type ReactNode, createContext, useContext } from 'react'
import { HintIcon } from '../AdminLayout'
import styles from './assets/SegmentNew.module.css'

const { Title } = Typography

export type SegmentProps = {
  children?: ReactNode
  footer?: ReactNode
  className?: string
  loading?: boolean
}

const SegmentContext = createContext({ loading: false })
const useSegmentContext = () => useContext(SegmentContext)

export const Segment = (props: SegmentProps) => {
  const { children, footer, className, loading = false, ...otherProps } = props

  return (
    <SegmentContext.Provider value={{ loading }}>
      <div className={className}>
        <Flex className={styles.mainFlex} {...otherProps} vertical>
          {children}
        </Flex>
        <Divider className={styles.divider} />
      </div>
    </SegmentContext.Provider>
  )
}

type SegmentHeaderProps = {
  children: ReactNode
  extra?: ReactNode
  hint?: string
}

const SegmentHeader = ({ children, extra, hint }: SegmentHeaderProps) => {
  const { loading } = useSegmentContext()
  return (
    <Flex justify='space-between' className={styles.titleFlex}>
      <Title level={5} color='dark.100' className={styles.title}>
        {loading ? (
          <Skeleton className={styles.titleSkeleton} />
        ) : (
          <Flex align='center' gap='small'>
            {children}
            {hint && <HintIcon title={hint} />}
          </Flex>
        )}
      </Title>
      {!loading && extra && <Flex>{extra}</Flex>}
    </Flex>
  )
}

const SegmentContent = ({ children }: { children: ReactNode }) => {
  const { loading } = useSegmentContext()
  return (
    <Flex vertical justify='flex-start' gap='small' className={styles.childrenFlex}>
      {loading ? <Skeleton className={styles.childrenSkeleton} /> : children}
    </Flex>
  )
}

const SegmentFooter = ({ children }: { children: ReactNode }) => {
  const { loading } = useSegmentContext()
  return (
    <Flex className={styles.footerFlex}>
      {loading ? <Skeleton className={styles.childrenSkeleton} /> : children}
    </Flex>
  )
}

Segment.Content = SegmentContent
Segment.Header = SegmentHeader
Segment.Footer = SegmentFooter
