import './assets/spin.css'
import { type SVGProps, forwardRef } from 'react'
import type {
  Defs,
  UntitledIconData,
  UntitledIconDataPath,
  UntitledIconDataPrefix,
} from './UntitledIconData'

const getPathStyle = (
  prefix: UntitledIconDataPrefix,
  index: number,
  { shape: _, ...path }: UntitledIconDataPath
): SVGProps<SVGPathElement> => {
  const first = index === 0
  switch (prefix) {
    case 'ul':
      return {
        stroke: 'currentColor',
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }
    case 'us':
      return {
        fill: 'currentColor',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }
    case 'udc':
      return {
        opacity: first ? 0.4 : 1,
        stroke: 'currentColor',
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }
    case 'udt':
      if (first) {
        return {
          opacity: 0.12,
          fill: 'currentColor',
        }
      }
      return {
        stroke: 'currentColor',
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }
    case 'custom':
      return path
  }
}

const getTransform: (flip: Flip) => string = flip => {
  switch (flip) {
    case 'horizontal':
      return 'scale(1, -1)'
    case 'vertical':
      return 'scale(-1, 1)'
    case 'both':
      return 'scale(-1, -1)'
    default:
      return ''
  }
}

const renderDefs = (defs: Defs) => {
  return defs.linearGradients?.map(({ stops, ...defItem }) => {
    return (
      <linearGradient key={defItem.id} {...defItem}>
        {stops.map((stop, index: number) => (
          <stop
            // biome-ignore lint/suspicious/noArrayIndexKey:
            key={index}
            {...stop}
          />
        ))}
      </linearGradient>
    )
  })
}

type Flip = 'horizontal' | 'vertical' | 'both'
export type UntitledIconProps = {
  icon: UntitledIconData
  color?: string
  size?: string | number
  flip?: Flip
  isSpinning?: boolean
} & Omit<SVGProps<SVGSVGElement>, 'viewBox' | 'fill' | 'xmlns'>

const UntitledIcon = forwardRef<SVGSVGElement, UntitledIconProps>(
  ({ icon, color, size, flip, isSpinning, ...props }: UntitledIconProps, ref) => (
    <svg
      ref={ref}
      {...props}
      style={{
        height: '1em',
        verticalAlign: '-0.125em',
        display: 'inline-block',
        fontSize: size,
        transform: flip ? getTransform(flip) : undefined,
        animation: isSpinning ? 'spin 1s linear infinite' : undefined,
        transition: 'transform 0.3s',

        ...props.style,
      }}
      viewBox={icon.viewBox ?? '0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {icon.paths?.map((path, key) => (
        <path
          // biome-ignore lint/suspicious/noArrayIndexKey:
          key={key}
          {...getPathStyle(icon.prefix, key, path)}
          d={path.shape}
          color={color}
        />
      ))}
      {icon.rects?.map((rect, key) => (
        // biome-ignore lint/suspicious/noArrayIndexKey:
        <rect key={key} {...rect} />
      ))}
      {icon.defs && <defs>{renderDefs(icon.defs)}</defs>}
    </svg>
  )
)
export default UntitledIcon
