import { createContext } from 'react'

export type FeatureFlagContextProps = {
  application: boolean
  loading: boolean
}

export const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  application: false,
  loading: true,
})
