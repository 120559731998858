import { UntitledIcon } from '@faceup/icons'
import { ulLinkExternal01 } from '@faceup/icons/ulLinkExternal01'
import { Text, Title } from '@mantine/core'
import type { TitleOrder } from '@mantine/core/lib/Title/Title'
import DOMPurify from 'dompurify'
import parse, { type DOMNode, type Element, attributesToProps, domToReact } from 'html-react-parser'
import { useLinkExternalConfirm } from '../hooks/useLinkExternalConfirm'
import { WysiwygBlockquote } from './WysiwygBlockquote'

type HtmlRendererProps = { html: string }

const isTag = (node: DOMNode): node is Element => node.type === 'tag'

/**
 * Renders HTML with some sanitization and a hook to make all links open a new window
 * TL;DR:
 *  - Sanitize the HTML with DOMPurify and add a hook to make all links open a new window
 *  - Parse the HTML with html-react-parser and add confirm alert for all links
 *  - Render the HTML
 */
export const HtmlRenderer = ({ html }: HtmlRendererProps) => {
  const linkExternalConfirm = useLinkExternalConfirm()
  // https://github.com/cure53/DOMPurify/blob/main/demos/hooks-target-blank-demo.html
  // Add a hook to make all links open a new window
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer')
    }
  })

  const renderAnchor = (domNode: Element) => {
    const props = attributesToProps(domNode.attribs)
    return (
      <a
        {...props}
        onClick={e => {
          e.preventDefault()
          if (typeof props['href'] === 'string') {
            linkExternalConfirm(props['href'])
          }
        }}
      >
        {domToReact(domNode.children as DOMNode[], options)}{' '}
        <UntitledIcon icon={ulLinkExternal01} />
      </a>
    )
  }

  const renderParagraph = (domNode: Element) => {
    const props = attributesToProps(domNode.attribs)
    return (
      <Text
        {...props}
        component='p'
        sx={{
          marginBottom: 0,
          paddingBottom: '1em',
        }}
      >
        {domToReact(domNode.children as DOMNode[], options)}
      </Text>
    )
  }

  const renderHeading = (domNode: Element) => {
    const props = attributesToProps(domNode.attribs)
    return (
      <Title {...props} order={Number(domNode.name.at(1)) as TitleOrder} pb='sm'>
        {domToReact(domNode.children as DOMNode[], options)}
      </Title>
    )
  }

  const renderBlockquote = (domNode: Element) => {
    const children = domToReact(domNode.children as DOMNode[], options)
    if (typeof children === 'string' || Array.isArray(children)) {
      return <WysiwygBlockquote>{children}</WysiwygBlockquote>
    }

    /**
     * The wysiwyg for some reasons injects <p> into a <blockquote> tag.
     * To avoid this, we need to get the children of the children.
     */
    return <WysiwygBlockquote>{children.props.children}</WysiwygBlockquote>
  }

  const cleanedHtml = DOMPurify.sanitize(html)
  const options = {
    replace: (domNode: DOMNode) => {
      if (!isTag(domNode)) {
        return domNode
      }

      switch (domNode.name) {
        case 'a':
          return renderAnchor(domNode)
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          return renderHeading(domNode)
        case 'p':
          return renderParagraph(domNode)
        case 'blockquote':
          return renderBlockquote(domNode)
        default:
          return domNode
      }
    },
  }
  const parsedCleanedHtml = parse(cleanedHtml, options)

  return <>{parsedCleanedHtml}</>
}
