import { gql, useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button } from '@faceup/ui'
import {
  Empty,
  Form,
  Input,
  SearchOutlined,
  Select,
  Typography,
  notification,
} from '@faceup/ui-base'
import {
  Country,
  INSTITUTION_PASSWORD_MAX_LENGTH,
  INSTITUTION_PASSWORD_MIN_LENGTH,
  Institution,
} from '@faceup/utils'
import { Center, Loader } from '@mantine/core'
import { useEffect, useState } from 'react'
import type { UserInstitution } from '../../Contexts/UserContext'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type {
  SearchPublicSchoolsQuery,
  SearchPublicSchoolsQueryVariables,
} from '../../__generated__/globalTypes'
import useConfigForProject from '../../hooks/useConfigForProject'
import { useMedia } from '../../mq'
import useRegion from '../../utils/useRegion'

const { Text } = Typography

const query = {
  SearchPublicSchools: gql`
    query SearchPublicSchoolsQuery($name: GraphQLString, $country: Country) {
      searchPublicSchools(name: $name, showTestingSchool: true, country: $country) {
        country
        regionId
        institutionId
        address
        name
        isRegistered
        isTesting
        hasActivePassword
        defaultFormId
      }
    }
  `,
}

const messages = defineMessages({
  schoolNameLabel: 'Shared.report.school',
  schoolNameError: 'FollowUp.SearchSchoolForm.schoolNameError',
  schoolNamePlaceholder: 'FollowUp.SearchSchoolForm.schoolNamePlaceholder',
  schoolNotFound: 'Shared.global.schoolNotFound',
  schoolPasswordLabel: 'FollowUp.SearchInstitution.passwordLabel',
  schoolPasswordPlaceholder: 'FollowUp.SearchInstitution.passwordPlaceholder',
  testingSchoolName: 'FollowUp.SearchInstitution.testingSchoolName',
  wrongAccessCode: 'FollowUp.CreateCompanyReportForm.wrongAccessCode',
})

type Props = {
  proceed: (props: UserInstitution) => void
}

const SearchSchoolForm = ({ proceed }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const isLgDown = useMedia('lgDown')
  const { discoverByReportPassword } = useRegion()

  const [passwordInput, setPasswordInput] = useState({ value: '', error: false })
  const [nameInput, setNameInput] = useState({ value: '', error: false })
  const [selectedSchool, setSelectedSchool] = useState<(typeof filteredSchools)[0] | null>(null)

  const validateForm = () => {
    let ok = true

    if (!selectedSchool) {
      setNameInput({ ...nameInput, error: true })
      ok = false
    }

    if (
      selectedSchool?.hasActivePassword &&
      (passwordInput.value.length < INSTITUTION_PASSWORD_MIN_LENGTH ||
        passwordInput.value.length > INSTITUTION_PASSWORD_MAX_LENGTH)
    ) {
      setPasswordInput({ ...passwordInput, error: true })
      ok = false
    }

    return ok
  }

  const resetForm = () => {
    setNameInput({ value: '', error: false })
    setPasswordInput({ value: '', error: false })
    setSelectedSchool(null)
  }

  const { isSk } = useConfigForProject()

  const [searchSchools, { loading: schoolsLoading, data, refetch, called }] = useLazyQuery<
    SearchPublicSchoolsQuery,
    SearchPublicSchoolsQueryVariables
  >(query.SearchPublicSchools, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    variables: {
      name: nameInput.value,
      country: isSk ? Country.Sk : undefined,
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies(nameInput.value):
  useEffect(() => {
    if (called) {
      refetch()
    } else {
      searchSchools()
    }
  }, [nameInput.value, called, refetch, searchSchools])

  const filteredSchools = data?.searchPublicSchools ?? []

  return (
    <>
      <Form.Item
        label={<FormattedMessage {...messages.schoolNameLabel} />}
        {...(nameInput.error && {
          validateStatus: 'error',
          help: <FormattedMessage {...messages.schoolNameError} />,
        })}
        data-cy='search-school-form-item'
        style={{ marginBottom: 6 }}
      >
        <Select
          filterOption={false}
          showSearch
          suffixIcon={null}
          loading={schoolsLoading}
          onSelect={(value: string) => {
            setSelectedSchool(
              filteredSchools?.find(school => school?.institutionId === value) ?? null
            )
            setNameInput({ ...nameInput, error: false })
          }}
          onSearch={value => setNameInput({ value, error: false })}
          placeholder={
            <>
              <SearchOutlined /> {formatMessage(messages.schoolNamePlaceholder)}
            </>
          }
          size='large'
          data-cy='search-school-form-select-input'
          allowClear
          showAction={['focus']}
          dropdownRender={menu => <div data-cy='search-school-form-select-menu'>{menu}</div>}
          options={filteredSchools.map(school => ({
            value: school.institutionId,
            label: school.isTesting ? (
              <FormattedMessage {...messages.testingSchoolName} />
            ) : (
              school.name
            ),
            address: school.address,
            isTesting: school.isTesting,
          }))}
          optionRender={school => {
            if (school.data['isTesting']) {
              return <Text strong>{school.label}</Text>
            }
            return (
              <>
                {school.label}
                <AddressText>{school.data['address']}</AddressText>
              </>
            )
          }}
          notFoundContent={
            schoolsLoading ? (
              <Center>
                <Loader />
              </Center>
            ) : nameInput.value === '' ? null : (
              <Empty
                imageStyle={{ height: 60 }}
                description={<FormattedMessage {...messages.schoolNotFound} />}
              />
            )
          }
        />
      </Form.Item>
      {selectedSchool?.hasActivePassword && (
        <Form.Item
          label={<FormattedMessage {...messages.schoolPasswordLabel} />}
          {...(passwordInput.error && {
            validateStatus: 'error',
            help: <FormattedMessage {...messages.wrongAccessCode} />,
          })}
          data-cy='search-school-form-item-password'
        >
          <Input
            autoComplete='off'
            size='large'
            data-cy='search-school-form-password'
            value={passwordInput.value}
            onChange={({ target: { value } }) => setPasswordInput({ value, error: false })}
          />
        </Form.Item>
      )}
      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
          <Button
            type='submit'
            isFullWidth={isLgDown}
            loading={loading}
            data-cy='search-school-form-continue'
            onClick={async () => {
              setLoading(true)
              if (!validateForm()) {
                setLoading(false)
                return
              }

              if (!selectedSchool?.hasActivePassword) {
                return proceed({
                  defaultFormId: selectedSchool?.defaultFormId ?? '',
                  institutionId: selectedSchool?.institutionId ?? '',
                  type: Institution.School,
                })
              }

              const discovery = await discoverByReportPassword(passwordInput.value.trim())
              if (discovery) {
                proceed({
                  defaultFormId: selectedSchool?.defaultFormId ?? '',
                  institutionId: selectedSchool?.institutionId ?? '',
                  password: passwordInput.value.trim(),
                  type: discovery.type,
                })

                resetForm()
              } else {
                setPasswordInput({ ...passwordInput, error: true })
              }
              setLoading(false)
            }}
          >
            <FormattedMessage {...sharedMessages.continue} />
          </Button>
        </div>
      </Form.Item>
    </>
  )
}

const AddressText = styled.div`
  font-size: 12px;
  color: rgba(80, 80, 80, 0.8);
`

export default SearchSchoolForm
