import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulXCircle } from '@faceup/icons/ulXCircle'
import { Alert as AntAlert, type AlertProps as AntAlertProps } from 'antd'

type AlertType = NonNullable<AlertProps['type']>

const icons: Record<AlertType, UntitledIconData> = {
  error: ulXCircle,
  info: ulInfoCircle,
  success: ulCheckCircle,
  warning: ulAlertCircle,
}

type AlertProps = AntAlertProps

export const Alert = (props: AlertProps) => {
  return <AntAlert icon={<UntitledIcon icon={icons[props.type ?? 'info']} />} showIcon {...props} />
}
