import { useLazyQuery } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import type { Region as AwsRegion } from '@faceup/utils'
import { useLocalStorage } from '@mantine/hooks'
import { sharedMessages } from '../Shared/translations'
import { useIntl } from '../TypedIntl'
import { graphql } from '../__generated__'

const query = {
  ReportPasswordDiscovery: graphql(`
    query ReportPasswordDiscoveryQuery($password: GraphQLString!) {
      reportPasswordDiscovery(password: $password) {
        regionId
        institutionId
        reportSourceId
        type
      }
    }
  `),
  ShortlinkDiscovery: graphql(`
    query ShortlinkDiscoveryQuery($shortlink: GraphQLString!) {
      shortlinkDiscovery(shortlink: $shortlink) {
        regionId
        institutionId
        reportSourceId
        type
      }
    }
  `),
  TokenDiscovery: graphql(`
    query TokenDiscoveryQuery($token: GraphQLString!) {
      region: tokenDiscovery(token: $token)
    }
  `),
}

export const REGION_KEY = 'REGION'

const useRegion = () => {
  const { formatMessage } = useIntl()
  // this should be SupportedRegion
  const [region, setRegion] = useLocalStorage<AwsRegion | null>({
    key: REGION_KEY,
    serialize: (value: AwsRegion | null): string => value ?? '',
    deserialize: (value: string): AwsRegion | null => (value ? (value as AwsRegion) : null),
  })

  const [fetchByReportPassword] = useLazyQuery(query.ReportPasswordDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [fetchByShortlink] = useLazyQuery(query.ShortlinkDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [fetchTokenDiscovery] = useLazyQuery(query.TokenDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const discoverByToken = async (token: string) => {
    const { data } = await fetchTokenDiscovery({
      variables: { token },
    })

    if (!data?.region) {
      return null
    }

    setRegion(data.region as AwsRegion)

    return data.region as AwsRegion
  }

  const discoverByReportPassword = async (password: string) => {
    const { data } = await fetchByReportPassword({
      variables: { password },
    })

    if (!data?.reportPasswordDiscovery?.regionId) {
      return null
    }

    setRegion(data.reportPasswordDiscovery.regionId as AwsRegion)

    return data.reportPasswordDiscovery
  }

  const discoverByShortlink = async (shortlink: string) => {
    const { data } = await fetchByShortlink({
      variables: { shortlink },
    })

    if (!data?.shortlinkDiscovery?.regionId) {
      return null
    }

    setRegion(data.shortlinkDiscovery.regionId as AwsRegion)

    return data.shortlinkDiscovery
  }

  return {
    region,
    setRegion,
    discoverByReportPassword,
    discoverByShortlink,
    discoverByToken,
  }
}

export default useRegion
