import { useMessage } from '@faceup/ui-base'
import { type FormEvent, type ReactNode, useContext, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { FormLocalizationContext } from '../FormLocalizationProvider'
import type { UseFormReturn } from '../wrappers'

export const submitButtonTextDefinition = ['save', 'send', 'add'] as const
export type SubmitButtonText = (typeof submitButtonTextDefinition)[number]

export type ReactHookFormProps<FV extends FieldValues = FieldValues> = {
  onSubmit: (values: FV) => Promise<boolean> | boolean
  form: UseFormReturn<FV>
  additionalButtons?: ReactNode
  isSubmitButtonDisabled?: boolean
  isSuccessMessageDisplayed?: boolean
  submitUnchanged?: boolean
  customSubmitSuccessText?: ReactNode
} & (
  | {
      submitButtonText: SubmitButtonText
      customSubmitButtonText?: never
    }
  | {
      submitButtonText?: never
      customSubmitButtonText: ReactNode
    }
)

export const useFormLogic = <FV extends FieldValues = FieldValues>(
  props: ReactHookFormProps<FV>
): {
  isLoading: boolean
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
  additionalButtons?: ReactNode
  isSubmitButtonDisabled: boolean
  submitButtonText: ReactNode
  dirtyFieldsCount: number
  reset: () => void
} => {
  const {
    onSubmit: originalOnSubmit,
    isSubmitButtonDisabled,
    form,
    submitButtonText = 'send',
    customSubmitButtonText,
    customSubmitSuccessText,
    additionalButtons,
    isSuccessMessageDisplayed = true,
    submitUnchanged = false,
  } = props
  const message = useMessage()
  const { submitButton, successMessage } = useContext(FormLocalizationContext)
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    form.handleSubmit(async values => {
      setIsLoading(true)
      const result = await originalOnSubmit(values)
      if (result) {
        switch (form.afterSubmit) {
          case 'persistValues':
            form.reset(values)
            break
          case 'resetValues':
            form.reset()
            break
        }

        if (isSuccessMessageDisplayed) {
          message.success(customSubmitSuccessText ?? successMessage[submitButtonText])
        }
      }
      setIsLoading(false)
    })()
  }

  const submitButtonFormattedText = customSubmitButtonText ?? submitButton[submitButtonText]

  return {
    isLoading,
    onSubmit,
    additionalButtons,
    isSubmitButtonDisabled: isSubmitButtonDisabled || (!submitUnchanged && !form.formState.isDirty),
    submitButtonText: submitButtonFormattedText,
    dirtyFieldsCount: Object.keys(form.formState.dirtyFields).length,
    reset: form.reset,
  }
}
