import { createContext } from 'react'

export type CustomizationColor = {
  primaryColor?: string
  darkColor?: string
}

export type CustomizationContextProps = {
  colors: CustomizationColor
  changeColors: (colors: CustomizationColor) => void
  isBrandingHidden: boolean
  changeIsBrandingHidden: (isHidden: boolean) => void
}

export const CustomizationContext = createContext<CustomizationContextProps>({
  colors: {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: This is a placeholder function
  changeColors: () => {},
  isBrandingHidden: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: This is a placeholder function
  changeIsBrandingHidden: () => {},
})
