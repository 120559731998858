import { Avatar, type AvatarProps } from '@faceup/ui-base'
import { type Ref, forwardRef } from 'react'

type MaybeT<T> = T | null | undefined

// Todo: make fragments great again
export type UserAvatarProps = {
  user: {
    id?: MaybeT<string>
    name?: MaybeT<string>
    profileImageUrl?: MaybeT<string>
    email?: MaybeT<string>
  }
} & AvatarProps

const MAX_INITIALS_COUNT = 3

const UserAvatar = forwardRef(
  ({ user, ...restProps }: UserAvatarProps, ref: Ref<HTMLDivElement>) => {
    const profileImageUrl = user?.profileImageUrl ?? undefined

    const formInitials = (str: string) =>
      str
        // split by space, dot or underscore
        .split(/[ ._]/)
        .map(part => part[0] ?? '')
        .slice(0, MAX_INITIALS_COUNT)
        .join('')
        .toUpperCase()

    const initials = formInitials((user?.name || user?.email?.split?.('@')?.[0]) ?? '')

    return (
      <Avatar ref={ref} size={32} src={profileImageUrl} alt={user?.name ?? ''} {...restProps}>
        {initials ?? ''}
      </Avatar>
    )
  }
)

export default UserAvatar
