import type { FormItemWrapperProps } from './typings'

export const splitPropsBetweenFormInputAndFormItemWrapper = <D = Record<string, unknown>>(
  props: FormItemWrapperProps & D
) => {
  const {
    withoutWrapper,
    label,
    description,
    variant,
    hint,
    secondary,
    withAsterisk,
    errorMessage,
    beforeInput,
    afterInput,
    forceZeroMinHeightForErrorMessage,
    ...formInputProps
  } = props
  return {
    formInputProps,
    formItemWrapperProps: {
      label,
      description,
      hint,
      secondary,
      withAsterisk,
      errorMessage,
      beforeInput,
      afterInput,
      forceZeroMinHeightForErrorMessage,
      withoutWrapper,
    },
  }
}
