import { FormLocalizationProvider } from '@faceup/form'
import { LocalizationProvider } from '@faceup/localization'
import {
  UiProvider as FUUiProvider,
  MantineProvider,
  type PagesTranslationItems,
  type PagesTranslationVariants,
  type UiProviderProps,
} from '@faceup/ui'
import { App, type Locale, UiBaseProvider, useMessage } from '@faceup/ui-base'
import { type ReactNode, useContext, useEffect, useState } from 'react'
import { LanguageContext } from './Contexts/LanguageContext'
import { SavedReportsProvider } from './Contexts/SavedReportsProvider'
import { sharedMessages } from './Shared/translations'
import { type DefineMessagesType, defineMessages, useIntl } from './TypedIntl'
import { useInstitution } from './hooks/useInstitution'

const messages = defineMessages({
  confirmRedirectMessageTitle: 'Shared.confirmRedirectMessageTitle',
  confirmRedirectMessageContent: 'Shared.confirmRedirectMessageContent',
  redirectOkButton: 'Shared.redirectOkButton',
  redirectCancelButton: 'Shared.redirectCancelButton',
  ok: 'Shared.ok',
  cancel: 'Shared.cancel',
  unsavedChangesCount: 'FollowUp.form.unsavedChangesCount',
  leaveQuestion: 'FollowUp.form.leaveQuestion',
  validationStringMin: 'Shared.validation.string.min',
  validationStringMax: 'Shared.validation.string.max',
})

const messagesPages: Record<
  Exclude<PagesTranslationVariants, 'formItems'>,
  DefineMessagesType<PagesTranslationItems>
> = {
  freeSchool: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.freeSchool.default.content',
  }),
  unregisteredSchool: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.unregisteredSchool.default.content',
  }),
  testingSchool: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.testingSchool.default.content',
  }),
  school: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.school.default.content',
  }),
  company: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.company.default.content',
  }),
  surveyBeforeSend: defineMessages({
    title: 'Shared.surveys.detail.beforeSendPageTitle',
    content: 'Shared.surveys.pages.beforeSend.content',
  }),
  surveyAfterSend: defineMessages({
    title: 'Shared.surveys.detail.afterSendPageTitle',
    content: 'Shared.surveys.pages.afterSend.content',
  }),
}

type AppProvidersProps = {
  children: ReactNode
  primaryColor?: string
  direction: 'ltr' | 'rtl'
  antLocale: Locale | undefined
}

const AppProviders = ({ children, primaryColor, direction, antLocale }: AppProvidersProps) => {
  const { formatMessage } = useIntl()
  const institution = useInstitution()
  const { name } = institution ?? { name: '' }
  const { language } = useContext(LanguageContext)
  const [isDefaultForm, setIsDefaultForm] = useState(false)
  const translations: NonNullable<UiProviderProps['pages']>['translations'] = {
    company: {
      title: formatMessage(messagesPages.company.title),
      content: formatMessage(messagesPages.company.content)
        .split('{name}')
        .join(name ?? ''),
    },
    school: {
      title: formatMessage(messagesPages.school.title),
      content: formatMessage(messagesPages.school.content)
        .split('{name}')
        .join(name ?? ''),
    },
    testingSchool: {
      title: formatMessage(messagesPages.testingSchool.title),
      content: formatMessage(messagesPages.testingSchool.content)
        .split('{name}')
        .join(name ?? ''),
    },
    freeSchool: {
      title: formatMessage(messagesPages.freeSchool.title),
      content: formatMessage(messagesPages.freeSchool.content)
        .split('{name}')
        .join(name ?? ''),
    },
    unregisteredSchool: {
      title: formatMessage(messagesPages.unregisteredSchool.title),
      content: formatMessage(messagesPages.unregisteredSchool.content)
        .split('{name}')
        .join(name ?? ''),
    },
    surveyBeforeSend: {
      title: formatMessage(messagesPages.surveyBeforeSend.title),
      content: formatMessage(messagesPages.surveyBeforeSend.content),
    },
    surveyAfterSend: {
      title: formatMessage(messagesPages.surveyAfterSend.title),
      content: formatMessage(messagesPages.surveyAfterSend.content),
    },
    formItems: {
      title: '',
      content: '',
    },
  }

  return (
    <LocalizationProvider language={language}>
      <App>
        <UiBaseProvider colorPrimary={primaryColor} locale={antLocale} direction={direction}>
          <UiProvider
            linkExternalConfirm={{
              confirmRedirectMessageTitle: formatMessage(messages.confirmRedirectMessageTitle),
              confirmRedirectMessageContent: formatMessage(messages.confirmRedirectMessageContent),
              redirectOkButton: formatMessage(messages.redirectOkButton),
              redirectCancelButton: formatMessage(messages.redirectCancelButton),
            }}
            pages={
              institution === null
                ? {
                    mother: { type: null, isTesting: null, plan: null },
                    isDefaultForm,
                    setIsDefaultForm,
                    translations,
                  }
                : {
                    mother: institution,
                    isDefaultForm,
                    setIsDefaultForm,
                    translations,
                  }
            }
            buttonConfirm={{
              confirm: formatMessage(messages.ok),
              cancel: formatMessage(messages.cancel),
            }}
            theme={{
              colors: { primary: primaryColor },
            }}
          >
            <FormLocalizationProvider
              submitButton={{
                send: formatMessage(sharedMessages.send),
                add: formatMessage(sharedMessages.add),
                save: formatMessage(sharedMessages.save),
              }}
              successMessage={{
                send: formatMessage(sharedMessages.savedMessage),
                add: formatMessage(sharedMessages.savedMessage),
                save: formatMessage(sharedMessages.savedMessage),
              }}
              modalForm={{
                cancelButton: formatMessage(sharedMessages.cancel),
              }}
              unsavedChanges={count => formatMessage(messages.unsavedChangesCount, { count })}
              leaveUnsavedChanges={formatMessage(messages.leaveQuestion)}
              yup={{
                mixed: {
                  default: formatMessage(sharedMessages.invalidInputError),
                  required: formatMessage(sharedMessages.invalidInputError),
                  notOneOf: formatMessage(sharedMessages.invalidInputError),
                  oneOf: formatMessage(sharedMessages.invalidInputError),
                },
                string: {
                  min: ({ min }) =>
                    formatMessage(messages.validationStringMin, {
                      min,
                    }),
                  max: ({ max }) =>
                    formatMessage(messages.validationStringMax, {
                      max,
                    }),
                },
              }}
            >
              <MantineProvider direction={direction}>
                <SavedReportsProvider>{children}</SavedReportsProvider>
              </MantineProvider>
            </FormLocalizationProvider>
          </UiProvider>
        </UiBaseProvider>
      </App>
    </LocalizationProvider>
  )
}

const UiProvider = ({ children, ...props }: UiProviderProps) => {
  const message = useMessage()
  useEffect(() => {
    if (['stage', 'preview'].includes(import.meta.env.VITE_ENVIRONMENT ?? '')) {
      message.warning({
        key: 'environment-info-box',
        content: `${import.meta.env.VITE_ENVIRONMENT} environment`,
        duration: 0,
        className: 'environment-info-box',
      })
    }
  }, [message])

  return <FUUiProvider {...props}>{children}</FUUiProvider>
}

export default AppProviders
