import { UntitledIcon } from '@faceup/icons'
import { Link, useMatchPath } from '@faceup/router'
import { Button, pagesIcons, usePageTemplate } from '@faceup/ui'
import type { PageIcon } from '@faceup/utils'
import { Stack } from '@mantine/core'
import { useEffect } from 'react'
import { type FragmentType, graphql, useFragment } from '../../../../../__generated__'
import { getPageRouteCallback } from '../../../../../utils/urls'

const fragments = {
  InstitutionLayoutNavbarContent_reportSource: graphql(`
    fragment InstitutionLayoutNavbarContent_reportSource on PublicReportSource {
      id
      isDefault

      pages(language: $language) {
        ... on Page {
          id
          icon
          title
        }
      }
    }
  `),
}

type NavbarContentProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutNavbarContent_reportSource>
}

export const NavbarContent = ({ reportSource: _reportSource }: NavbarContentProps) => {
  const reportSource = useFragment(
    fragments.InstitutionLayoutNavbarContent_reportSource,
    _reportSource
  )
  const matchPath = useMatchPath()
  const { fillObject, setIsDefaultForm } = usePageTemplate()
  const pages = reportSource.pages.map(page =>
    page.__typename === 'Page' ? fillObject(page) : null
  )

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  return (
    <Stack>
      {pages.map(page => {
        if (page?.__typename !== 'Page') {
          return null
        }

        const { id, title, icon } = page
        const untitledIcon = pagesIcons[icon as PageIcon]
        const routeCallback = getPageRouteCallback(page)
        const isItemActive = matchPath(routeCallback)

        return (
          <Button
            key={id}
            component={Link}
            to={routeCallback}
            iconBefore={untitledIcon ? <UntitledIcon icon={untitledIcon} size={19} /> : undefined}
            variant='text'
            isFullWidth
            color={isItemActive ? undefined : 'dark'}
            contentAlign='left'
            sx={{
              fontSize: 14,
              paddingLeft: 15,
              paddingRight: 15,
              width: '100%',
            }}
          >
            {title}
          </Button>
        )
      })}
    </Stack>
  )
}
