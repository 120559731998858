import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  type FileDescriptor,
  createClosingComment,
  createComment,
  loadFileWithReportKey,
  readComment,
} from '@faceup/crypto'
import { sharedMessages, useIntl } from '@faceup/localization'
import { type Attachment, generateFileCompareKey } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { FormItemType, omitNullInArray } from '@faceup/utils'
import { useState } from 'react'
import type {
  ChatMessageInput,
  CreateRedactedReport,
  CreateRedactedReportVariables,
  UseCreateRedactedReportQuery,
  UseCreateRedactedReportQueryVariables,
} from '../__generated__/globalTypes'
import type { RedactedTexts, SubText } from '../components'
import { FollowUpChatMessageFragments } from '../components/Chat/FollowUpChatMessage'
import { redactCharacter, useCryptoErrorHandler } from '../utils'
import { useClosingComment } from './useClosingComment'
import { parseGqlAnswerToAnswersType } from './useManageReportCustomInputs'
import {
  usePrepareFormItemAnswersForMutation,
  usePrepareFormItemAnswersForMutationFragments,
} from './usePrepareFormItemAnswersForMutation'
import {
  usePrepareReportPayload,
  usePrepareReportPayloadFragments,
} from './usePrepareReportPayload'
import { UseReportBodyFragments, useReportBody } from './useReportBody'

const query = {
  UseCreateRedactedReportQuery: gql`
    query UseCreateRedactedReportQuery(
      $reportId: CompanyReportGlobalId!
      $sourceLanguage: Language
      $targetLanguage: Language
    ) {
      report(reportId: $reportId) {
        id
        category {
          id
        }
        company {
          id
          isE2EE

          ...usePrepareReportPayloadFragments_company
        }
        reportSource {
          id
          defaultLanguage
        }
        closingCommentTranslation(sourceLanguage: $sourceLanguage, targetLanguage: $targetLanguage) {
          id
          body
          bodyNonce
        }
        answers {
          id

          formItem {
            id
            type
          }
          ...usePrepareFormItemAnswersForMutationFragments_answer
        }
        followUpActivities(page: 0, rowsPerPage: 10000) {
          items {
            ...FollowUpChatMessage_followUpActivityNode
          }
        }
        comments(page: 0, rowsPerPage: 500) {
          totalCount
          items {
            id
            translation {
              id
              ciphertext
              nonce
            }
            parentComment {
              id
            }
            attachments {
              id
              name
              url
              mimetype
            }
          }
        }
        attachments {
          id
          name
          url
          mimetype
        }

        ...UseReportBody_reportNoVariables
      }
      systemInfo {
        id

        ...usePrepareReportPayloadFragments_system
      }
      memberViewer {
        id
        keys {
          id
          publicKey
        }
      }
    }
    ${UseReportBodyFragments.UseReportBody_reportNoVariables}
    ${usePrepareReportPayloadFragments.usePrepareReportPayloadFragments_company}
    ${usePrepareReportPayloadFragments.usePrepareReportPayloadFragments_system}
    ${
      usePrepareFormItemAnswersForMutationFragments.usePrepareFormItemAnswersForMutationFragments_answer
    }
    ${
      // TODO: Should be refactored into separate hook?
      FollowUpChatMessageFragments.FollowUpChatMessage_followUpActivityNode
    }
  `,
}

const mutations = {
  CreateRedactedReport: gql`
    mutation CreateRedactedReport($input: CreateRedactedReportInput!) {
      createRedactedReport(input: $input) {
        createdReport {
          id
          tag
        }
      }
    }
  `,
}

type UseCreateRedactedReport = (
  motherId: string,
  reportId: string
) => {
  createRedactedReport: (input: RedactedTexts) => Promise<string | null>
  loading: boolean
}

export const useCreateRedactedReport: UseCreateRedactedReport = motherId => {
  const { formatMessage } = useIntl()
  const { decrypt } = useReportBody()
  const { decrypt: decryptClosingComment } = useClosingComment()
  const prepareReportPayload = usePrepareReportPayload()
  const cryptoErrorHandler = useCryptoErrorHandler()
  const { prepareForm } = usePrepareFormItemAnswersForMutation()
  const [loading, setLoading] = useState<boolean>(false)

  const [getReportData] = useLazyQuery<
    UseCreateRedactedReportQuery,
    UseCreateRedactedReportQueryVariables
  >(query.UseCreateRedactedReportQuery, {
    variables: {
      reportId: '',
      sourceLanguage: null,
      targetLanguage: null,
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [create] = useMutation<CreateRedactedReport, CreateRedactedReportVariables>(
    mutations.CreateRedactedReport,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const createRedactedReport = async (input: RedactedTexts) => {
    setLoading(true)
    const { data } = await getReportData({
      variables: {
        reportId: input.reportId,
      },
    })
    const report = data?.report

    if (!report) {
      setLoading(false)
      return null
    }
    const decryptedData = await decrypt(report)
    const company = report.company
    const system = data?.systemInfo

    if (!system) {
      setLoading(false)
      return null
    }

    const loadAttachments = async (
      attachments: (FileDescriptor & { id: string })[]
    ): Promise<Attachment[]> =>
      await Promise.all(
        attachments
          ?.filter(attachment => !isTextFullyAnonymized(input.attachments?.[attachment.id] ?? ''))
          .map(async attachment => {
            const file = await loadFileWithReportKey(attachment, report.encryptionKey ?? '')
            if (file.isErr()) {
              throw new Error(file.error.message)
            }

            return {
              file: file.value,
              compareKey: generateFileCompareKey(file.value),
              loading: false,
              processed: true,
            }
          })
      )

    const attachments: Attachment[] = await loadAttachments(report.attachments)

    const payload = await prepareReportPayload(
      company,
      system,
      company.isE2EE,
      {
        victimName: input.sender ?? decryptedData.senderName,
        moreInfo: input.moreInfo ?? decryptedData.moreInfo,
      },
      attachments
    )

    if (payload.isErr()) {
      cryptoErrorHandler(payload.error.message)
      setLoading(false)
      return null
    }

    const { recipientKeys } = payload.value
    const encryptionKey = recipientKeys.find(({ id }) => id === data?.memberViewer?.id)?.key ?? ''

    const originalAnswers = await prepareForm(
      report.reportSource.id,
      report.category.id
    ).getValuesForMutation(parseGqlAnswerToAnswersType(report.answers))
    const answers = originalAnswers.map(answer => {
      const getValue: () => string[] | null = () => {
        if (input.formItems?.[answer.formItemId]) {
          const formItem = report.answers.find(
            item => item.formItem.formItemId === answer.formItemId
          )?.formItem
          switch (formItem?.type) {
            case FormItemType.Select:
            case FormItemType.MultiSelect:
              if (isTextFullyAnonymized(input.formItems?.[answer.formItemId] ?? '')) {
                return null
              }
              // We need to return original value (because we pass value to input, not formItemId)
              return answer.values
            default:
              return input.formItems[answer.formItemId]
                ? [input.formItems[answer.formItemId] as string]
                : answer.values
          }
        }
        return answer.values
      }
      return {
        ...answer,
        values: getValue(),
      }
    })

    const followUpDecryptedMessages: { id: string; text: string; attachments?: Attachment[] }[] =
      await Promise.all(
        omitNullInArray(report.followUpActivities?.items ?? []).map(async message => {
          if (input.chatMessages?.[message?.id ?? ''] !== undefined) {
            return {
              id: message?.id ?? '',
              text: input.chatMessages?.[message?.id ?? ''] ?? '',
              attachments: message?.attachments
                ? await loadAttachments(message.attachments)
                : undefined,
            }
          }

          const payloadComment = await readComment(
            message?.translation?.ciphertext ?? '',
            message?.translation?.nonce ?? '',
            message?.report?.encryptionKey ?? ''
          )

          if (payloadComment.isErr()) {
            console.error(payloadComment)
            notification.error({
              message: formatMessage(sharedMessages.encryptionError),
              description: payloadComment.error.message,
            })
            throw new Error(payloadComment.error.message)
          }

          return {
            id: message?.id ?? '',
            text: payloadComment.value,
            attachments: message?.attachments
              ? await loadAttachments(message.attachments)
              : undefined,
          }
        })
      )

    const followUpMessages: ChatMessageInput[] = await Promise.all(
      followUpDecryptedMessages.map(async message => {
        const payload = await createComment(
          message.text,
          encryptionKey,
          message.attachments?.map(attachment => attachment.file)
        )
        if (payload.isErr()) {
          console.error(payload)
          notification.error({
            message: formatMessage(sharedMessages.encryptionError),
            description: payload.error.message,
          })
          throw new Error(payload.error.message)
        }
        return {
          ...payload.value,
          originalCommentId: message.id,
        }
      })
    )

    const decryptedInternalComments = await Promise.all(
      omitNullInArray(report.comments?.items ?? []).map(async message => {
        if (input.internalCommentMessages?.[message?.id ?? ''] !== undefined) {
          return {
            id: message?.id ?? '',
            text: input.internalCommentMessages?.[message?.id ?? ''] ?? '',
            attachments: message?.attachments
              ? await loadAttachments(message.attachments)
              : undefined,
          }
        }

        const payload = await readComment(
          message?.translation?.ciphertext ?? '',
          message?.translation?.nonce ?? '',
          report?.encryptionKey ?? ''
        )
        if (payload.isErr()) {
          console.error(payload)
          notification.error({
            message: formatMessage(sharedMessages.encryptionError),
            description: payload.error.message,
          })

          throw new Error(payload.error.message)
        }
        return {
          ...payload,
          id: message?.id ?? '',
          attachments: message?.attachments
            ? await loadAttachments(message.attachments)
            : undefined,
        }
      })
    )

    const internalComments = await Promise.all(
      decryptedInternalComments
        .filter(message => message.text !== undefined)
        .map(async message => {
          const payload = await createComment(
            message.text,
            encryptionKey,
            message.attachments?.map(attachment => attachment.file)
          )
          if (payload.isErr()) {
            console.error(payload)
            notification.error({
              message: formatMessage(sharedMessages.encryptionError),
              description: payload.error.message,
            })
            throw new Error(payload.error.message)
          }
          return {
            ...payload.value,
            originalCommentId: message.id,
          }
        })
    )

    const closingCommentPayload = input.closingComment
      ? await createClosingComment(input.closingComment, encryptionKey)
      : await createClosingComment(
          await decryptClosingComment(
            report.closingCommentTranslation?.body ?? null,
            report.closingCommentTranslation?.bodyNonce ?? null,
            report.encryptionKey
          ),
          encryptionKey
        )

    if (closingCommentPayload.isErr()) {
      console.error(closingCommentPayload?.error.message)
      notification.error({
        message: formatMessage(sharedMessages.encryptionError),
        description: closingCommentPayload?.error.message,
      })
      throw new Error(closingCommentPayload?.error.message)
    }

    const { body: closingCommentBody, nonce: closingCommentNonce } = closingCommentPayload.value

    const redactedChatUsers = getRedactedUsers(input.chatMemberUsername)
    const redactedCommentUserIds = getRedactedUsers(input.internalCommentUsername)
    const { data: createdReportData } = await create({
      variables: {
        input: {
          ...payload.value,
          motherId,
          originalReportId: input.reportId,
          answers,
          followUpMessages,
          internalComments,
          redactedChatUsers,
          redactedCommentUserIds,
          reportClosing: {
            newClosingComment: {
              body: closingCommentBody,
              nonce: closingCommentNonce,
            },
            newClosingReason: undefined,
          },
        },
      },
    })

    setLoading(false)
    return createdReportData?.createRedactedReport?.createdReport?.id ?? null
  }

  return {
    loading,
    createRedactedReport,
  }
}

const getRedactedUsers: (usernames: SubText | undefined) => { id: string }[] = usernames =>
  omitNullInArray(
    usernames
      ? Object.entries(usernames).map(([id, username]) => {
          if (username === undefined) {
            return null
          }
          return { id }
        })
      : []
  )

const isTextFullyAnonymized: (text: string) => boolean = text => {
  const length = text.length
  if (length === 0) {
    // Empty string cannot be logically anonymized
    return false
  }
  const generatedAnonymized = Array(length).fill(redactCharacter).join('')
  return text === generatedAnonymized
}
