import { Card as BaseCard } from '@faceup/ui-base'
import { type HTMLAttributes, type ReactNode, createContext, useContext } from 'react'
import { Segment, type SegmentProps } from '../CoreComponentsNew/SegmentNew'
import styles from './assets/CardNew.module.css'

export type CardNewProps = {
  loading?: boolean
  children?: ReactNode
  className?: string
} & HTMLAttributes<HTMLDivElement>

const CardContext = createContext({ loading: false })
const useCardContext = () => useContext(CardContext)

export const CardNew = (props: CardNewProps) => {
  const { children, loading = false, className, ...otherProps } = props

  return (
    <CardContext.Provider value={{ loading }}>
      <BaseCard className={`${styles.card}  ${className}`} {...otherProps}>
        <CardSegment loading={loading} {...otherProps}>
          {children}
        </CardSegment>
      </BaseCard>
    </CardContext.Provider>
  )
}

const CardSegment = (props: SegmentProps) => {
  const { loading } = useCardContext()
  return <Segment className={styles.segment} loading={loading} {...props} />
}

CardNew.Segment = CardSegment
CardNew.Header = Segment.Header
CardNew.Content = Segment.Content
CardNew.Footer = Segment.Footer
