/**
 * Both compile- and runtime-safe way to check all items in array has order property.
 */
export const canBeReordered = <D extends { order: number | null }>(
  array: D[]
): array is (D & { order: number })[] => array.every(item => item.order !== null)

/**
 * Immutable.
 *
 * https://stackoverflow.com/a/53109467
 */
export const reorder = <D extends { order: number }>(
  array: D[],
  fromIndex: number,
  toIndex: number
): D[] => {
  const copy = [...array]
  return [...copy.splice(toIndex, 0, ...copy.splice(fromIndex, 1)), ...copy]?.map(
    (item, index) => ({ ...item, order: index })
  )
}

export const CHUNK_SIZE = 100
export const chunker = <T>(rows: T[], chunkSize = CHUNK_SIZE) =>
  rows.reduce(
    (acc, row) =>
      (acc.at(-1)?.length ?? 0) < chunkSize
        ? [...acc.slice(0, -1), [...(acc?.at(-1) ?? []), row]]
        : [...acc, [row]],
    new Array<T[]>([])
  )

export const arrayContainsArrayInGivenOrder = (array: string[], required: string[]) => {
  let index = 0

  for (const item of array) {
    if (item === required[index]) {
      index += 1
    } else {
      index = 0
    }
    if (index === required.length) {
      return true
    }
  }

  return false
}

export const sort = <T>(items: T[], compare?: (a: T, b: T) => number): T[] =>
  [...items].sort(compare)

export const areArraysEqual = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) {
    return false
  }

  const sortedArray1 = sort(array1)
  const sortedArray2 = sort(array2)

  return sortedArray1.every((value, index) => value === sortedArray2[index])
}

export const removeItemsFromArray = <T>(itemsToRemove: T[], originalArray: T[]): T[] => {
  const set = new Set(originalArray)

  for (const item of itemsToRemove) {
    set.delete(item)
  }

  return Array.from(set)
}
