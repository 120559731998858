import { useQuery } from '@apollo/client'
import { Navigate } from '@faceup/router'
import { CustomizationContext } from '@faceup/ui'
import { LoadingOverlay, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { createBrowserHistory } from 'history'
import { type ReactNode, useContext, useEffect, useLayoutEffect } from 'react'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { UserContext } from '../../Contexts/UserContext'
import { graphql } from '../../__generated__'
import { useCustomValues } from '../../hooks/useCustomValues'
import { type HeaderVariant, type LayoutVariant, desktopBreakpoint } from '../../utils/constants'
import useReportAuth from '../../utils/useReportAuth'
import { AppWrapper } from './AppWrapper'
import { AppFooter, Header, Navbar, WebFooter } from './components'
import { useMenuOpen } from './hooks/useMenuOpen'

const query = graphql(`
    query InstitutionLayoutCompanyQuery(
      $institutionId: PublicCompanyGlobalId
      $password: GraphQLInstitutionPassword
      $language: Language!
    ) {
      publicReportSourceByReportPassword(
        institutionId: $institutionId
        password: $password
      ) {
        id
        reportFormColor
        logoSize
        institution {
          id
          config {
            id
            primaryColor
            isBrandingHidden
            ...AppWrapper_config
          }
          partner {
            id
            config {
              id
              primaryColor
              isBrandingHidden
              ...AppWrapper_config
            }
          }
        }
        
        ...InstitutionLayoutWebFooter_reportSource
        ...InstitutionLayoutNavbar_reportSource
        ...Header_reportSource
        ...AppFooter_reportSource
      }
    }
  `)

type InstitutionLayoutProps = {
  headerVariant?: HeaderVariant
  children?: ReactNode
  variant?: LayoutVariant
}
export const InstitutionLayout = ({
  children,
  variant = 'complex',
  headerVariant,
}: InstitutionLayoutProps) => {
  const { reportSource: userInstitution, logout: userLogout } = useContext(UserContext)
  const { isAuthenticated, logout: reportLogout } = useReportAuth()
  const { client } = useContext(LayoutContext)
  const { changeColors, changeIsBrandingHidden } = useContext(CustomizationContext)

  const customValues = useCustomValues()

  const { language } = useContext(LanguageContext)

  const [opened, setOpened] = useMenuOpen()

  const history = createBrowserHistory()

  const isApp = client === 'app'
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints[desktopBreakpoint]})`)

  useLayoutEffect(() => {
    history.listen(() => {
      if (
        isAuthenticated() &&
        client !== 'app' &&
        ((history.action === 'PUSH' && ['/create', '/'].includes(history.location.pathname)) ||
          (history.action === 'REPLACE' && history.location.pathname === '/check'))
      ) {
        reportLogout()
        window.location.href = '/'
      }
    })
  }, [history, isAuthenticated, reportLogout, client])

  useEffect(() => {
    if (customValues?.reportColor) {
      changeColors({ primaryColor: customValues.reportColor })
    }
  }, [changeColors, customValues])

  const {
    data: companyData,
    loading: loadingCompany,
    called: calledCompany,
  } = useQuery(query, {
    variables: {
      institutionId: userInstitution?.institutionId,
      ...(userInstitution?.password && { password: userInstitution.password }),
      language,
    },
    onCompleted: data => {
      changeColors({
        primaryColor:
          data.publicReportSourceByReportPassword?.reportFormColor ??
          data.publicReportSourceByReportPassword?.institution?.config?.primaryColor ??
          data.publicReportSourceByReportPassword?.institution?.partner?.config?.primaryColor ??
          undefined,
      })
      changeIsBrandingHidden(
        data.publicReportSourceByReportPassword?.institution.config?.isBrandingHidden ??
          data.publicReportSourceByReportPassword?.institution.partner?.config?.isBrandingHidden ??
          false
      )
    },
    skip: !userInstitution,
  })

  const loading = loadingCompany || !calledCompany

  if (!userInstitution?.institutionId) {
    return <Navigate to={routes => routes.home()} replace />
  }

  if (loading || !userInstitution.institutionId) {
    return <LoadingOverlay visible />
  }

  const reportSource = companyData?.publicReportSourceByReportPassword

  if (!reportSource) {
    userLogout()
    return <Navigate to={routes => routes.home()} replace />
  }

  const config =
    companyData.publicReportSourceByReportPassword?.institution.config?.isBrandingHidden !== null
      ? companyData.publicReportSourceByReportPassword?.institution.config
      : companyData.publicReportSourceByReportPassword?.institution.partner?.config

  return (
    <AppWrapper
      variant={variant}
      headerComponent={
        <Header
          isMenuOpened={opened}
          setIsMenuOpened={setOpened}
          reportSource={reportSource}
          variant={headerVariant ?? variant}
        />
      }
      navbarComponent={<Navbar reportSource={reportSource} isOpened={opened} variant={variant} />}
      footerComponent={
        <>
          {isApp && <AppFooter reportSource={reportSource} shouldShowSecondaryMenu={opened} />}
          {!isApp &&
            (isDesktop || variant === 'simple' ? undefined : (
              <WebFooter reportSource={reportSource} shouldShowLanguagesDropdown={opened} />
            ))}
        </>
      }
      config={config ?? undefined}
    >
      {children}
    </AppWrapper>
  )
}
