import { KeyDerivation } from '../atoms/derivation'

export const OPSLIMIT = 3
// so why exactly is this different from HASH_MEMLIMIT?
// i dont remember the reason, but it is strange decision at least
export const PREHASH_MEMLIMIT = 20480000
export const HASH_MEMLIMIT = 10240000
export const MEMLIMIT = 20480000
export const FILE_CHUNK_SIZE = 40960
export const FILE_CHUNK_ENCRYPTED_SIZE = 40960 + 17 // sodium.crypto_secretstream_xchacha20poly1305_ABYTES
export const FILE_ENCRYPTION_CONTEXT = KeyDerivation.toContext('Attchmnt')
export const FILE_CHUNK_SUBKEY_ID_LEN = 1
export const RECOVERY_FILE_NAME = 'FaceUpRecoveryKey.txt'
export const CURRENT_ENCRYPTION_VERSION = 2 as const
export const RECOVERY_KEY_LENGTH = 16
export const RECOVERY_KEY_CONTEXT = KeyDerivation.toContext('Recovery')
export const DERIVED_KEY_LENGTH = 256
export const RANDOM_ARGON_HASH_REPLACEMENT =
  '$argon2id$v=19$m=262144,t=3,p=1$U3YoMBCfMBb0ti8boY+emw$PfLdyWM0k4xF7A3AUwmMVvDHWqoyYD23mGkQWXgwTHI'
export const RANDOM_TOTP = '000000'
export const VICTIM_IDENTITY_LENGTH = 6

/**
 * Sodium constants, that are exported here, so we dont have to
 * spin up sodium for accessing a constant
 */

export const crypto_secretbox_NONCEBYTES = 24
export const crypto_secretbox_KEYBYTES = 32

export const crypto_box_NONCEBYTES = 24
export const crypto_pwhash_SALTBYTES = 16

export const crypto_secretstream_xchacha20poly1305_KEYBYTES = 32
