import { SupportedRegion } from '@faceup/utils'
import { ok } from 'neverthrow'
import { Asymmetric } from './atoms/asymmetric'
import { fromBase64, toBase64 } from './atoms/convertors'
import { Hashing } from './atoms/hashing'
import { Random } from './atoms/random'
import { Symmetric } from './atoms/symmetric'
import { getCurrentEncryptionVersion } from './end2end/login'
import { getPersonalKeys } from './keys'
import {
  CURRENT_ENCRYPTION_VERSION,
  DERIVED_KEY_LENGTH,
  RANDOM_ARGON_HASH_REPLACEMENT,
  crypto_secretbox_KEYBYTES,
} from './utils/constants'
import { createErr, mapErr } from './utils/general'

type PrehashPayload = {
  password: string
  salt: string
  version?: 1 | typeof CURRENT_ENCRYPTION_VERSION
}

export const prehashPassword = async (payload: PrehashPayload) => {
  const version = payload?.version ?? CURRENT_ENCRYPTION_VERSION

  if (version === 1) {
    return ok({
      passwordKey: payload.password,
      passwordKeyPrehash: payload.password,
    })
  }

  const salt = Hashing.toSalt(await fromBase64(payload.salt))
  const passwordKey = await Hashing.derivePrehashKey(
    crypto_secretbox_KEYBYTES,
    Hashing.toPassword(payload.password),
    salt
  )

  if (passwordKey.isErr()) {
    return mapErr(passwordKey, 'Could not derive password key')
  }

  const passwordKeyPrehash = await Hashing.derivePrehashKey(
    crypto_secretbox_KEYBYTES,
    Hashing.toPassword(passwordKey.value),
    salt
  )

  if (passwordKeyPrehash.isErr()) {
    return mapErr(passwordKeyPrehash, 'Could not derive password key prehash')
  }

  return ok({
    passwordKey: await toBase64(passwordKey.value),
    passwordKeyPrehash: await toBase64(passwordKeyPrehash.value),
  })
}

export const changePassword = async (
  password: string,
  keys: { publicKey: string; privateKey: string } | 'generate' | 'infer'
) => {
  const salt = await Hashing.generateSalt()
  const nonce = await Symmetric.generateNonce()

  const resolveKeys = async () => {
    if (keys === 'generate') {
      return Asymmetric.generateKey()
    }

    if (keys === 'infer') {
      return ok(await getPersonalKeys())
    }

    return ok({
      publicKey: await fromBase64(keys.publicKey),
      privateKey: await fromBase64(keys.privateKey),
    })
  }

  const resolvedKeys = await resolveKeys()
  if (resolvedKeys.isErr()) {
    return mapErr(resolvedKeys, 'Could not resolve keys')
  }

  const { publicKey, privateKey } = resolvedKeys.value

  const prehashedPassword = await prehashPassword({
    password,
    salt: await toBase64(salt),
    version: CURRENT_ENCRYPTION_VERSION,
  })

  if (prehashedPassword.isErr()) {
    return mapErr(prehashedPassword, 'No public or private key')
  }

  if (!publicKey || !privateKey) {
    return createErr('No public or private key', new Error('No public or private key'))
  }

  const { passwordKeyPrehash, passwordKey } = prehashedPassword.value
  const privateKeyEncrypted = await Symmetric.encrypt(
    privateKey,
    Symmetric.toKey(await fromBase64(passwordKey)),
    nonce
  )

  if (privateKeyEncrypted.isErr()) {
    return mapErr(privateKeyEncrypted, 'Could not encrypt private key')
  }

  return ok({
    passwordPrehash: passwordKeyPrehash,
    publicKey: await toBase64(publicKey),
    privateKeyEncrypted: await toBase64(privateKeyEncrypted.value),
    salt: await toBase64(salt),
    nonce: await toBase64(nonce),
    passwordKey,
  })
}

export const convertPrehashToHash = async (prehash: string, APP_SPECIFIC_SALT: string) => {
  /**
   * Derive sent key using app specific salt
   */
  const derivedKey = await Hashing.deriveHashKey(
    DERIVED_KEY_LENGTH,
    Hashing.toPassword(await fromBase64(prehash)),
    Hashing.toSalt(await fromBase64(APP_SPECIFIC_SALT))
  )

  if (derivedKey.isErr()) {
    return mapErr(derivedKey, 'Could not derive key while converting prehash to hash')
  }

  /**
   * Hash the derived key, so its secure to store the derived key
   */
  return Hashing.hashForStorage(Hashing.toPassword(derivedKey.value))
}

export const verifyPrehashWithHash = async (
  prehash: string,
  hash: string | null | undefined,
  APP_SPECIFIC_SALT: string,
  version: number = getCurrentEncryptionVersion()
) => {
  if (version !== getCurrentEncryptionVersion()) {
    return createErr('Invalid version', new Error('Invalid version'))
  }

  /**
   * Derive sent key using app specific salt
   */
  const derivedKey = await Hashing.deriveHashKey(
    DERIVED_KEY_LENGTH,
    Hashing.toPassword(await fromBase64(prehash)),
    Hashing.toSalt(await fromBase64(APP_SPECIFIC_SALT))
  )

  if (derivedKey.isErr()) {
    return mapErr(derivedKey, 'Could not derive key while verifying prehash with hash')
  }

  /**
   * Hash is done even if the digest is not found in db to comply with function constant time
   */
  const isAuthenticated = await Hashing.verifyHash(
    Hashing.toPassword(derivedKey.value),
    Hashing.toHash(hash ?? RANDOM_ARGON_HASH_REPLACEMENT)
  )

  if (isAuthenticated.isErr()) {
    return mapErr(isAuthenticated, 'Could not verify hash while verifying prehash with hash')
  }

  /**
   * Why like this? Because we dont want user to have to check two values
   * for error, e.g. isAuthenticated.isErr() || !isAuthenticated.value.
   */
  return isAuthenticated.value
    ? ok(true)
    : createErr('Invalid password', new Error('Invalid password'))
}

export const buildIdentityToken = async (
  id: string,
  userType: string,
  tokenVersion: number,
  REGION: string,
  JWT_SECRET: string,
  remember = false
) => {
  const jwt = await import('jsonwebtoken')
  const payload = {
    id: String(id),
    userType,
    tokenVersion,
    // because user with same ID can be in multiple regions, we need to differentiate, in which region he was logged in
    // we can unset this after we fully migrate to UUIDs
    region: userType === 'FuAdmin' ? Object.values(SupportedRegion) : [REGION],
  }

  const hourInSeconds = 60 * 60

  const expiresIn = remember
    ? // week
      7 * 24 * hourInSeconds
    : // 12 hours
      12 * hourInSeconds

  return jwt.sign(payload, JWT_SECRET, { expiresIn })
}

export const generatePin = async () => {
  const pin = (
    (await Random.generateInt(99999)).toString() + (await Random.generateInt(99999)).toString()
  ).padStart(10, '0')

  const victimPayload = await changePassword(pin, 'generate')

  if (victimPayload.isErr()) {
    return mapErr(victimPayload, 'Could not generate pin')
  }

  return ok({ victimPayload: victimPayload.value, victimPin: pin })
}

export const serializePin = (pin: string) => pin.match(/.{1,4}/g)?.join(' ') ?? ''

export const deserializePin = (pin: string) => pin.split(' ').join('')

export const parsePinToParts = (pin: string) => {
  const trimmedPin = pin.trim()
  const version: 1 | 2 = trimmedPin.length === 14 ? 1 : CURRENT_ENCRYPTION_VERSION
  const [identity, password] =
    version === 1
      ? [trimmedPin.substring(0, 6), trimmedPin.substring(6, 14)]
      : [trimmedPin.substring(0, 6), trimmedPin.substring(6, 16)]

  return { identity, password, version }
}
