import { Box, Burger, Flex, MediaQuery } from '@mantine/core'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../../Contexts/LayoutContext'
import { type FragmentType, graphql, useFragment } from '../../../../../../__generated__'
import { desktopBreakpoint } from '../../../../../../utils/constants'
import LanguageDropdown from '../../../../../LanguageDropdown'
import { LogoutButton } from '../../../../../LogoutButton'
import { PartnerInstitutionLogo } from '../PartnerInstitutionLogo'
import { PagesMenu } from './components'

const fragments = {
  HeaderComplexWithPages_reportSource: graphql(`
    fragment HeaderComplexWithPages_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage

      ...PartnerInstitutionLogo_reportSource
      ...PagesMenu_reportSource
    }
  `),
}

type HeaderComplexWithPagesProps = {
  reportSource: FragmentType<typeof fragments.HeaderComplexWithPages_reportSource>
  isMenuOpened: boolean
  setIsMenuOpened: (isOpened: boolean) => void
}

export const HeaderComplexWithPages = ({
  isMenuOpened,
  setIsMenuOpened,
  reportSource: _reportSource,
}: HeaderComplexWithPagesProps) => {
  const reportSource = useFragment(fragments.HeaderComplexWithPages_reportSource, _reportSource)
  const { client } = useContext(LayoutContext)
  const isApp = client === 'app'

  return (
    <>
      <PartnerInstitutionLogo reportSource={reportSource} />
      <MediaQuery smallerThan={desktopBreakpoint} styles={{ display: 'none' }}>
        <Flex
          wrap='nowrap'
          mx={64}
          sx={{
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <PagesMenu reportSource={reportSource} />
        </Flex>
      </MediaQuery>
      <Box>
        {reportSource.languages.length > 1 && (
          <MediaQuery
            smallerThan={desktopBreakpoint}
            styles={{
              display: 'none',
            }}
          >
            <Box>
              <LanguageDropdown
                availableLanguages={reportSource.languages}
                defaultLanguage={reportSource.defaultLanguage}
              />
              {isApp && (
                <LogoutButton
                  sx={{
                    color: '#232323',
                  }}
                />
              )}
            </Box>
          </MediaQuery>
        )}
        <MediaQuery
          largerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Burger
            opened={isMenuOpened}
            onClick={() => setIsMenuOpened(!isMenuOpened)}
            color='#232323'
            size={22}
          />
        </MediaQuery>
      </Box>
    </>
  )
}
