import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

/**
 * This is a temporary blockquote component,
 * so the blockquote rendered in follow-up looks
 * the same as the one rendered in the WYSIWYG editor.
 */
export const WysiwygBlockquote = ({ children }: Props) => {
  return (
    <blockquote
      style={{
        fontSize: '1.125rem',
        lineHeight: 1.55,
        margin: '1rem 0',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
        padding: '1rem 1.25rem',
        color: '#062D46',
        borderLeft: '0.375rem solid #dee2e6',
      }}
    >
      {children}
    </blockquote>
  )
}
