import { storageGet, storageRemove, storageSet } from '@faceup/utils'
import { ok } from 'neverthrow'
import { fromBase64, toBase64 } from './atoms/convertors'
import { Symmetric } from './atoms/symmetric'
import { CURRENT_ENCRYPTION_VERSION } from './utils/constants'
import { mapErr } from './utils/general'

const PBK_STORAGE_KEY = 'PBK_STORAGE_KEY'
const PRK_STORAGE_KEY = 'PRK_STORAGE_KEY'

const getItem = (key: string) =>
  storageGet('localStorage', key) ?? storageGet('sessionStorage', key)

export const getPersonalKeys = async () => {
  const privateKey = getItem(PRK_STORAGE_KEY) ?? null
  const publicKey = getItem(PBK_STORAGE_KEY) ?? null

  return {
    privateKey: privateKey ? await fromBase64(privateKey) : null,
    publicKey: publicKey ? await fromBase64(publicKey) : null,
  }
}

export const getUnmodifiedKeys = () => {
  const privateKey = getItem(PRK_STORAGE_KEY) ?? null
  const publicKey = getItem(PBK_STORAGE_KEY) ?? null

  return {
    privateKey: privateKey ?? null,
    publicKey: publicKey ?? null,
  }
}

type SavePersonalKeysPayload = {
  passwordKey?: string
  publicKey: string
  privateKey: string
  nonce?: string
  rememberMe: boolean
  version: number
}

export const savePersonalKeys = async (payload: SavePersonalKeysPayload) => {
  const setItem = (key: string, value: string) =>
    storageSet(payload.rememberMe ? 'localStorage' : 'sessionStorage', key, value)

  if (payload.version === CURRENT_ENCRYPTION_VERSION && payload.passwordKey && payload.nonce) {
    const decryptedKey = await Symmetric.decrypt(
      Symmetric.toCiphertext(await fromBase64(payload.privateKey)),
      Symmetric.toKey(await fromBase64(payload.passwordKey)),
      Symmetric.toNonce(await fromBase64(payload.nonce))
    )

    if (decryptedKey.isErr()) {
      return mapErr(decryptedKey, 'Could not decrypt the private key when saving personal keys')
    }

    setItem(PRK_STORAGE_KEY, await toBase64(decryptedKey.value))
  } else {
    setItem(PRK_STORAGE_KEY, payload.privateKey)
  }

  setItem(PBK_STORAGE_KEY, payload.publicKey)

  return ok(undefined)
}

type SaveSSOKeysPayload = {
  publicKey: string
  privateKey: string
  rememberMe: boolean
}

export const saveSSOKeys = async (payload: SaveSSOKeysPayload) => {
  const setItem = (key: string, value: string) =>
    storageSet(payload.rememberMe ? 'localStorage' : 'sessionStorage', key, value)

  setItem(PBK_STORAGE_KEY, payload.publicKey)
  setItem(PRK_STORAGE_KEY, payload.privateKey)
}

export const deletePersonalKeys = () => {
  const removeItem = (key: string) => {
    storageRemove('localStorage', key)
    storageRemove('sessionStorage', key)
  }

  removeItem(PRK_STORAGE_KEY)
  removeItem(PBK_STORAGE_KEY)
}
