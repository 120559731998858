import { Avatar as AntAvatar, type AvatarProps as AntAvatarProps } from 'antd'
import { Group } from 'antd/es/avatar'
import { forwardRef } from 'react'
import { useUiBaseConfig } from './UiBaseProvider'

type Color = 'primary' | 'purple'

type MapColors = Record<
  Color,
  {
    backgroundColor: string
    color: string
  }
>

export type AvatarProps = { color?: Color } & AntAvatarProps

const InternalAvatar = forwardRef<HTMLSpanElement, AvatarProps>(
  ({ color = 'primary', size = 'default', ...props }, ref) => {
    const { colors } = useUiBaseConfig()
    const mapColors: MapColors = {
      primary: {
        backgroundColor: colors.primary1,
        color: colors.primary5,
      },
      purple: {
        backgroundColor: colors.purple1,
        color: colors.purple5,
      },
    }
    return (
      <AntAvatar
        ref={ref}
        {...props}
        size={size}
        style={{
          color: mapColors[color].color,
          backgroundColor: mapColors[color].backgroundColor,
          ...props.style,
        }}
      />
    )
  }
)

type CompoundedComponent = typeof InternalAvatar & {
  Group: typeof Group
}

export const Avatar = InternalAvatar as CompoundedComponent

Avatar.Group = Group
