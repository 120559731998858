import { Result, ok } from 'neverthrow'
import { fromBase64, toBase64, toText } from './atoms/convertors'
import { Symmetric } from './atoms/symmetric'
import { encryptFileWithReportKey } from './files'
import { readReportKey, readReportKeyWithoutContext } from './report'
import { mapErr } from './utils/general'

export const readComment = async (
  ciphertext: string | null,
  nonce: string,
  recipientKey: string
) => {
  if (!ciphertext) {
    return ok('')
  }

  const reportKey = await readReportKey(recipientKey)

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when reading comment')
  }

  const decryptedText = await Symmetric.decrypt(
    Symmetric.toCiphertext(await fromBase64(ciphertext)),
    Symmetric.toKey(reportKey.value),
    Symmetric.toNonce(await fromBase64(nonce))
  )

  if (decryptedText.isErr()) {
    return mapErr(decryptedText, 'Cannot decrypt comment when reading')
  }

  return ok(await toText(decryptedText.value))
}

export const createCommentWithoutContext = async (
  text: string,
  recipientKey: string,
  recipientPublicKey: string,
  recipientPrivateKey: string
) => {
  const reportKey = await readReportKeyWithoutContext(
    recipientKey,
    recipientPublicKey,
    recipientPrivateKey
  )

  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when creating comment')
  }

  const nonce = await Symmetric.generateNonce()
  const ciphertext = await Symmetric.encrypt(text, Symmetric.toKey(reportKey.value), nonce)

  if (ciphertext.isErr()) {
    return mapErr(ciphertext, 'Cannot create comment without context')
  }

  return ok({
    ciphertext: await toBase64(ciphertext.value),
    nonce: await toBase64(nonce),
  })
}

export const createComment = async (
  text: string,
  recipientKey: string,
  attachments: File[] = []
) => {
  const reportKey = await readReportKey(recipientKey)
  if (reportKey.isErr()) {
    return mapErr(reportKey, 'Could not read report key when creating comment')
  }

  const nonce = await Symmetric.generateNonce()
  const ciphertext = await Symmetric.encrypt(text, Symmetric.toKey(reportKey.value), nonce)

  if (ciphertext.isErr()) {
    return mapErr(ciphertext, 'Cannot create comment without context')
  }

  const encryptedAttachments = Result.combine(
    await Promise.all(
      attachments.map(attachment => encryptFileWithReportKey(attachment, reportKey.value))
    )
  )

  if (encryptedAttachments.isErr()) {
    return mapErr(encryptedAttachments, 'Could not encrypt attachments when creating comment')
  }

  return ok({
    ciphertext: await toBase64(ciphertext.value),
    nonce: await toBase64(nonce),
    attachments: encryptedAttachments.value as File[],
  })
}
