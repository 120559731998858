import type { Branded } from '@faceup/utils'
import { ok } from 'neverthrow'
import { createErr, getSodium } from '../utils/general'

export type AsymmetricPublicKey = Branded<Uint8Array, 'AsymmetricPublicKey'>
export type AsymmetricPrivateKey = Branded<Uint8Array, 'AsymmetricPrivateKey'>
export type AsymmetricCiphertext = Branded<Uint8Array, 'AsymmetricCiphertext'>

export class Asymmetric {
  public static async encrypt(message: string | Uint8Array, publicKey: AsymmetricPublicKey) {
    const sodium = await getSodium()

    try {
      const ciphertext = sodium.crypto_box_seal(message, publicKey) as AsymmetricCiphertext

      return ok(ciphertext)
    } catch (e) {
      return createErr('Could not encrypt the message with asymmetric cipher', e)
    }
  }

  public static async decrypt(
    ciphertext: AsymmetricCiphertext,
    publicKey: AsymmetricPublicKey,
    privateKey: AsymmetricPrivateKey
  ) {
    const sodium = await getSodium()

    try {
      const message = sodium.crypto_box_seal_open(ciphertext, publicKey, privateKey)

      return ok(message)
    } catch (e) {
      return createErr('Could not decrypt the message with asymmetric cipher', e)
    }
  }

  public static async generateKey() {
    const sodium = await getSodium()
    try {
      const { publicKey, privateKey } = sodium.crypto_box_keypair()

      return ok({
        publicKey: Asymmetric.toPublicKey(publicKey),
        privateKey: Asymmetric.toPrivateKey(privateKey),
      })
    } catch (e) {
      return createErr('Could not generate the key pair', e)
    }
  }

  public static toCiphertext(ciphertext: Uint8Array) {
    return ciphertext as AsymmetricCiphertext
  }

  public static toPublicKey(key: Uint8Array) {
    return key as AsymmetricPublicKey
  }

  public static toPrivateKey(key: Uint8Array) {
    return key as AsymmetricPrivateKey
  }
}
