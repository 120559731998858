import type { Branded } from '@faceup/utils'
import { ok } from 'neverthrow'
import { crypto_secretbox_NONCEBYTES } from '../utils/constants'
import { createErr, getSodium } from '../utils/general'
import { Random } from './random'

export type SymmetricNonce = Branded<Uint8Array, 'SymmetricNonce'>
export type SymmetricKey = Branded<Uint8Array, 'SymmetricKey'>
export type SymmetricCiphertext = Branded<Uint8Array, 'SymmetricCiphertext'>

export class Symmetric {
  public static async encrypt(
    message: string | Uint8Array,
    key: SymmetricKey,
    nonce: SymmetricNonce
  ) {
    const sodium = await getSodium()

    try {
      const ciphertext = sodium.crypto_secretbox_easy(message, nonce, key) as SymmetricCiphertext

      return ok(ciphertext)
    } catch (e) {
      return createErr('Could not encrypt the message with symmetric cipher', e)
    }
  }

  public static async decrypt(
    ciphertext: SymmetricCiphertext,
    key: SymmetricKey,
    nonce: SymmetricNonce
  ) {
    const sodium = await getSodium()

    try {
      const message = sodium.crypto_secretbox_open_easy(ciphertext, nonce, key)

      return ok(message)
    } catch (e) {
      return createErr('Could not decrypt the message with symmetric cipher', e)
    }
  }

  public static async generateNonce() {
    return Symmetric.toNonce(await Random.generateBytes(crypto_secretbox_NONCEBYTES))
  }

  public static async generateKey() {
    const sodium = await getSodium()

    return Symmetric.toKey(sodium.crypto_secretbox_keygen())
  }

  public static toCiphertext(ciphertext: Uint8Array) {
    return ciphertext as SymmetricCiphertext
  }

  public static toNonce(nonce: Uint8Array) {
    return nonce as SymmetricNonce
  }

  public static toKey(key: Uint8Array) {
    return key as SymmetricKey
  }
}
