import { gql, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import {
  RecipientsOnReportForm,
  type useRecipientsFragments_publicCompany,
  type useRecipientsQuery,
  type useRecipientsQueryVariables,
} from '../../../__generated__/globalTypes'

type useRecipientsFragments_publicCompany_recipients = NonNullable<
  useRecipientsFragments_publicCompany['recipients']
>[number]

export type Recipient = {
  isSelected: boolean
  canBeRemoved: boolean
  canBeAdded: boolean
  isEditable: boolean
} & useRecipientsFragments_publicCompany_recipients

const useRecipientsFragments = {
  useRecipientsFragments_publicCompany: gql`
    fragment useRecipientsFragments_publicCompany on PublicCompany {
      id
      recipients {
        id
        publicKey
        name
        profileImageUrl
        bio
        isPartner
        accessibleCategories(reportSourceId: $reportSourceId) {
          id
        }
      }
    }
  `,
}

const query = gql`
  query useRecipientsQuery(
    $organizationalUnitId: PublicCompanyGlobalId!
    $reportSourceId: PublicReportSourceGlobalId!
  ) {
    publicInstitution(institutionId: $organizationalUnitId) {
      id
      ...useRecipientsFragments_publicCompany
    }
  }
  ${useRecipientsFragments.useRecipientsFragments_publicCompany}
`

export const useRecipients = (
  selectedOrganizationalUnitId?: string,
  selectedCategoryId?: string,
  reportSourceId?: string,
  recipientsOnReportForm?: RecipientsOnReportForm | undefined
) => {
  const { data, loading } = useQuery<useRecipientsQuery, useRecipientsQueryVariables>(query, {
    fetchPolicy: 'network-only',
    variables: {
      organizationalUnitId: selectedOrganizationalUnitId ?? '',
      reportSourceId: reportSourceId ?? '',
    },
    skip: !selectedOrganizationalUnitId || !reportSourceId,
  })

  const initialState = useMemo(() => {
    if (!selectedCategoryId) {
      return []
    }

    const selectedInstitution = data?.publicInstitution

    return (
      selectedInstitution?.recipients
        .filter(
          member =>
            member.accessibleCategories == null ||
            member.accessibleCategories.map(category => category.id).includes(selectedCategoryId)
        )
        ?.map((member, _index, recipients) => {
          // we want to select by default => even if they are not visible they are still sent to server
          const isSelected = true
          const canBeRemoved =
            recipientsOnReportForm === RecipientsOnReportForm.Editable && recipients.length > 1
          const canBeAdded = !isSelected

          return {
            ...member,
            isSelected,
            isEditable:
              recipientsOnReportForm === RecipientsOnReportForm.Editable &&
              (canBeAdded || canBeRemoved),
            canBeRemoved,
            canBeAdded,
          }
        }) ?? []
    )
  }, [data?.publicInstitution, recipientsOnReportForm, selectedCategoryId])

  const [recipients, setRecipients] = useState<Recipient[]>(initialState)

  useEffect(() => {
    setRecipients(initialState)
  }, [initialState])

  return {
    recipients,
    areRecipientsLoading: loading,
    changeRecipient: (member: Recipient) => {
      if (
        !member.isEditable ||
        (!member.canBeAdded && !member.isSelected) ||
        (!member.canBeRemoved && member.isSelected)
      ) {
        return
      }
      setRecipients(previousRecipients => {
        const newRecipients = previousRecipients.map(pm => {
          const selected = pm.id === member.id ? !pm.isSelected : pm.isSelected
          const canBeAdded = !selected
          return {
            ...pm,
            canBeAdded,
            isSelected: selected,
          }
        })

        const canBeRemoved = newRecipients.filter(recipient => recipient.isSelected).length >= 2

        return newRecipients.map(recipient => ({
          ...recipient,
          isEditable:
            recipientsOnReportForm === RecipientsOnReportForm.Editable &&
            (recipient.canBeAdded || canBeRemoved),
          canBeRemoved,
        }))
      })
    },
  }
}
