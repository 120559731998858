import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulMessageCheckSquare } from '@faceup/icons/ulMessageCheckSquare'
import { ulPlus } from '@faceup/icons/ulPlus'
import { ulSearchSm } from '@faceup/icons/ulSearchSm'
import { Link, type RouteCallback } from '@faceup/router'
import { Button, type ButtonProps } from '@faceup/ui'
import { SimpleGrid } from '@mantine/core'
import { type ReactNode, useContext } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, graphql, useFragment } from '../../__generated__'
import { Institution } from '../../__generated__/globalTypes'
import { useInstitution } from '../../hooks/useInstitution'
import { useIsCheckReportAvailable } from '../../hooks/useIsCheckReportAvailable'

const fragments = {
  InstitutionLayoutMenuItems_reportSource: graphql(`
    fragment InstitutionLayoutMenuItems_reportSource on PublicReportSource {
      id

      ...IsCheckReportAvailable_reportSource
    }
  `),
}

const messages = defineMessages({
  createReport: 'FollowUp.InstitutionLayout.createReport',
  createReportForTestingSchool: 'FollowUp.InstitutionLayout.createReportForTestingSchool',
  checkReport: 'FollowUp.CheckReport.title',
  backToSchoolList: 'FollowUp.InstitutionLayout.backToSchoolList',
})

type MenuItemBase = {
  label: ReactNode
  visible?: boolean
  icon: UntitledIconData
  variant?: 'filled' | 'light'
  dataCy?: string
  key: string
}

type MenuItemLinkProp = MenuItemBase & {
  url: RouteCallback
  onClick?: never
}

type MenuItemButtonProp = MenuItemBase & {
  onClick: () => void
  url?: never
}

type MenuItem = MenuItemLinkProp | MenuItemButtonProp

type MenuItemsProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutMenuItems_reportSource>
  direction: 'horizontal' | 'vertical'
}

export const MenuItems = ({ reportSource: _reportSource, direction }: MenuItemsProps) => {
  const reportSource = useFragment(fragments.InstitutionLayoutMenuItems_reportSource, _reportSource)
  const { isCheckReportAvailable } = useIsCheckReportAvailable({ reportSource })
  const { logout } = useContext(UserContext)
  const institution = useInstitution()

  const isTestingSchool = institution?.isTesting === true && institution.type === Institution.School

  const menuItems: MenuItem[] = [
    {
      key: 'createReport',
      url: routes => routes.createReport(),
      icon: ulPlus,
      label: isTestingSchool ? (
        <FormattedMessage {...messages.createReportForTestingSchool} />
      ) : (
        <FormattedMessage {...messages.createReport} />
      ),
      variant: 'filled',
      dataCy: 'menu-item-create',
    },
    {
      key: 'checkReport',
      url: routes => routes.checkReport(),
      icon: ulMessageCheckSquare,
      label: <FormattedMessage {...messages.checkReport} />,
      visible: isCheckReportAvailable,
      dataCy: 'menu-item-check',
    },
    {
      key: 'logout',
      onClick: logout,
      icon: ulSearchSm,
      label: <FormattedMessage {...messages.backToSchoolList} />,
      visible: isTestingSchool,
    },
  ]

  const filteredMenuItems = menuItems.filter(item => item.visible !== false)
  const cols = direction === 'horizontal' ? filteredMenuItems.length : 1

  return (
    <SimpleGrid cols={cols}>
      {filteredMenuItems.map(item => {
        const isFilled = item.variant === 'filled'

        const buttonProps: ButtonProps = {
          iconBefore: <UntitledIcon icon={item.icon} size={19} />,
          variant: isFilled ? 'primary' : 'secondary',

          sx: theme => ({
            fontSize: 14,
            paddingLeft: 15,
            paddingRight: 15,
            '&:hover': {
              // antdesign hack
              color: isFilled ? theme.white : undefined,
            },
          }),
        }
        if (item.url) {
          return (
            <Button<typeof Link>
              key={item.key}
              component={Link}
              to={item.url}
              data-cy={item.dataCy ?? undefined}
              {...buttonProps}
            >
              {item.label}
            </Button>
          )
        }
        return (
          <Button
            key={item.url}
            onClick={item.onClick}
            data-cy={item.dataCy ?? undefined}
            {...buttonProps}
          >
            {item.label}
          </Button>
        )
      })}
    </SimpleGrid>
  )
}
