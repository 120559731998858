import type { Branded } from '@faceup/utils'
import { ok } from 'neverthrow'
import {
  HASH_MEMLIMIT,
  OPSLIMIT,
  PREHASH_MEMLIMIT,
  crypto_pwhash_SALTBYTES,
} from '../utils/constants'
import { createErr, getSodium } from '../utils/general'
import { Random } from './random'

export type HashingPassword = Branded<Uint8Array | string, 'HashingPassword'>
export type HashingSalt = Branded<Uint8Array, 'HashingSalt'>
export type HashingDerived = Branded<Uint8Array, 'HashingDerived'>
export type HashingHash = Branded<string, 'HashingHash'>

/**
 * 1. crypto_pwhash -> slow, memory intensive hashing, designed for passwords.
 * Short input and takes a lot of time, so if you were trying precomputing hashes, it would be slow.
 *
 * 2. crypto_generichash -> fast, designed for general purpose.
 * We dont use it so much.
 *
 * 3. (in KeyDerivation, but related) crypto_kdf_derive_from_key -> fast, designed for deriving subkeys from a master key,
 * which is already long enough.
 */
export class Hashing {
  public static async derivePrehashKey(
    length: number,
    password: HashingPassword,
    salt: HashingSalt
  ) {
    return Hashing.deriveKey(length, password, salt, PREHASH_MEMLIMIT)
  }

  public static async deriveHashKey(length: number, password: HashingPassword, salt: HashingSalt) {
    return Hashing.deriveKey(length, password, salt, HASH_MEMLIMIT)
  }

  public static async hashForStorage(password: HashingPassword) {
    const sodium = await getSodium()
    try {
      const hash = sodium.crypto_pwhash_str(password, OPSLIMIT, HASH_MEMLIMIT)

      return ok(Hashing.toHash(hash))
    } catch (e) {
      return createErr('Could not hash password for storage', e)
    }
  }

  public static async verifyHash(password: HashingPassword, hash: HashingHash) {
    const sodium = await getSodium()
    try {
      const isValid = sodium.crypto_pwhash_str_verify(hash, password)

      return ok(isValid)
    } catch (e) {
      return createErr('Could not verify hash', e)
    }
  }

  public static async generateSalt() {
    return Hashing.toSalt(await Random.generateBytes(crypto_pwhash_SALTBYTES))
  }

  /**
   * Do not use on its own, if you dont know what are you doing.
   * This is public just for edge case purpose.
   */
  public static async deriveKey(
    length: number,
    password: HashingPassword,
    salt: HashingSalt,
    MEMLIMIT: number
  ) {
    const sodium = await getSodium()
    try {
      const derivedKey = sodium.crypto_pwhash(
        length,
        password,
        salt,
        OPSLIMIT,
        MEMLIMIT,
        sodium.crypto_pwhash_ALG_ARGON2ID13
      )

      return ok(Hashing.toDerivedKey(derivedKey))
    } catch (e) {
      return createErr('Could not derive key', e)
    }
  }

  public static toDerivedKey(derivedKey: Uint8Array) {
    return derivedKey as HashingDerived
  }

  public static toPassword(password: string | Uint8Array) {
    return password as HashingPassword
  }

  public static toSalt(salt: Uint8Array) {
    return salt as HashingSalt
  }

  public static toHash(hash: string) {
    return hash as HashingHash
  }
}
