import type Sodium from 'libsodium-wrappers-sumo'
import { type Err, err } from 'neverthrow'

export const getSodium = async (): Promise<typeof Sodium> => {
  const { default: sodium } = await import('libsodium-wrappers-sumo')
  await sodium.ready

  return sodium
}

/**
 * @deprecated Use `neverthrow` Result type instead
 */
export const handleError = (e: unknown) => {
  if (e instanceof Error) {
    return e.message
  }

  return 'Unspecified encryption error'
}

export const mapErr = (
  error: Err<unknown, { message: string; trace: string[]; exception: unknown }>,
  message: string
) => {
  const oldMessage = error.error.message

  return err({
    message, // this should be a summary of the top-level context
    trace: [...error.error.trace, oldMessage], // this is the whole path to trace the error
    exception: error.error.exception, // this is what will be passed to sentry, with all of the details
  })
}

export const createErr = (message: string, exception: unknown) =>
  err({
    message,
    trace: [],
    exception,
  })
