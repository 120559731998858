import { Language } from './enums'

export const DEFAULT_LANGUAGE = Language.En_Us

export const languageEnumToBasename = (language: Language | string) =>
  language.toLowerCase()?.replace('_', '-')

// https://www.andiamo.co.uk/resources/right-to-left-languages/
export const RTL_LANGUAGES = [Language.Ar, Language.Fa, Language.Fa_Af, Language.He, Language.Ur]

export const SUPPORTED_BASENAMES = Object.values(Language).map(languageEnumToBasename)

export const getValidBasename = () => {
  const basename = window.location.pathname.split('/')?.[1] || ''
  return SUPPORTED_BASENAMES.includes(basename) ? `/${basename}` : '/'
}

export const convertBasenameToLanguage = (basename: string): Language => {
  const capitalizeFirstLetter = (str?: string) =>
    (str?.charAt(0)?.toUpperCase() ?? '') + str?.slice(1)?.toLowerCase()

  // biome-ignore lint/style/noParameterAssign:
  basename = basename.replace('/', '')

  let parts: string[] = []
  if (basename.includes('_')) {
    parts = basename.split('_')
  } else {
    parts = basename.split('-')
  }

  const firstPart = parts[0]
  const languageKey = capitalizeFirstLetter(firstPart)

  const secondPart = parts[1]
  if (secondPart) {
    const regionKey = capitalizeFirstLetter(secondPart)
    const languageValue = Language[`${languageKey}_${regionKey}` as keyof typeof Language]
    if (languageValue) {
      return languageValue
    }
  }

  // match cs-CZ -> cs
  if (Language[languageKey as keyof typeof Language]) {
    return languageKey as Language
  }

  return Language[languageKey as keyof typeof Language] ?? DEFAULT_LANGUAGE
}
