import { gql } from '@apollo/client'
import { readEncryptedField, readReport } from '@faceup/crypto'
import { useCryptoErrorHandler } from '@faceup/report'
import { useCallback } from 'react'
import type {
  UseReportBody_reportNoVariables,
  UseReportBody_reportVariables,
} from '../__generated__/globalTypes'

export const UseReportBodyFragments = {
  UseReportBody_reportVariables: gql`
    fragment UseReportBody_reportVariables on CompanyReport {
      id
      encryptionKey
      translation(sourceLanguage: $sourceLanguage, targetLanguage: $targetLanguage) {
        id
        body
        bodyNonce
      }
    }
  `,
  UseReportBody_reportNoVariables: gql`
    fragment UseReportBody_reportNoVariables on CompanyReport {
      id
      encryptionKey
      translation {
        id
        body
        bodyNonce
      }
    }
  `,
}

type ReportBody = {
  senderName: string | null
  moreInfo: string
}

type DecryptFn = (report: Report) => Promise<ReportBody>
type DecryptTextValueFn = (body: string, nonce: string, recipientKey: string) => Promise<string>

type UseReportBodyReturn = {
  decrypt: DecryptFn
  decryptTextValue: DecryptTextValueFn
}

export type Report = UseReportBody_reportVariables | UseReportBody_reportNoVariables

/**
 * Decrypts report body. Uses anonymous message as default value (i.e. when sender has no name).
 */
export const useReportBody = (): UseReportBodyReturn => {
  const handleError = useCryptoErrorHandler()

  const decrypt: DecryptFn = useCallback(
    async (report: Report) => {
      const body: string = report.translation?.body ?? ''
      const nonce: string = report.translation?.bodyNonce ?? ''
      const recipientKey: string = report.encryptionKey ?? ''

      const payload = await readReport(body, nonce, recipientKey)

      if (payload.isErr()) {
        handleError(payload.error.message)
        throw new Error(payload.error.message)
      }

      const { moreInfo, victimName: senderName } = payload.value
      return {
        senderName,
        moreInfo: moreInfo ?? '',
      }
    },
    [handleError]
  )

  const decryptTextValue: DecryptTextValueFn = useCallback(
    async (body: string, nonce: string, recipientKey: string) => {
      const payload = await readEncryptedField<{ value: string }>(body, nonce, recipientKey)

      if (payload.isErr()) {
        handleError(payload.error.message)
        throw new Error(payload.error.message)
      }

      return payload.value?.value ?? ''
    },
    [handleError]
  )

  return {
    decrypt,
    decryptTextValue,
  }
}
