import { getSodium } from '../utils/general'

export const toBase64 = async (input: Uint8Array | string) => {
  const sodium = await getSodium()

  return sodium.to_base64(input)
}

export const fromBase64 = async (input: string) => {
  const sodium = await getSodium()

  return sodium.from_base64(input)
}

export const toText = async (input: Uint8Array) => {
  const sodium = await getSodium()

  return sodium.to_string(input)
}

export const fromHex = async (input: string) => {
  const sodium = await getSodium()

  return sodium.from_hex(input)
}

export const toHex = async (input: Uint8Array) => {
  const sodium = await getSodium()

  return sodium.to_hex(input)
}
