import { getSodium } from '../utils/general'

export class Random {
  public static async generateBytes(length: number) {
    const sodium = await getSodium()
    return sodium.randombytes_buf(length)
  }

  public static async generateBytesDeterministic(length: number, seed: Uint8Array) {
    const sodium = await getSodium()
    return sodium.randombytes_buf_deterministic(length, seed)
  }

  public static async generateInt(endExcluded: number, startIncluded = 0) {
    const sodium = await getSodium()
    return sodium.randombytes_uniform(endExcluded - startIncluded) + startIncluded
  }
}
