import { ok } from 'neverthrow'
import { Asymmetric } from './atoms/asymmetric'
import { fromBase64, fromHex, toBase64, toHex } from './atoms/convertors'
import { KeyDerivation } from './atoms/derivation'
import { Random } from './atoms/random'
import { Symmetric } from './atoms/symmetric'
import { deserializePin, serializePin } from './password'
import { RECOVERY_KEY_CONTEXT, RECOVERY_KEY_LENGTH } from './utils/constants'
import { mapErr } from './utils/general'

export const recoverPrivateKey = async (
  privateKeyRecovery: string,
  recoveryKey: string,
  privateKeyRecoveryNonce: string
) => {
  const deserializedRecoveryKey = await deserializeRecoveryKey(recoveryKey)
  const encryptionKey = await convertRecoveryKeyToEncryptionKey(deserializedRecoveryKey)
  if (encryptionKey.isErr()) {
    return mapErr(encryptionKey, 'Could not convert recovery key to encryption key')
  }

  const privateKey = await Symmetric.decrypt(
    Symmetric.toCiphertext(await fromBase64(privateKeyRecovery)),
    Symmetric.toKey(await fromBase64(encryptionKey.value)),
    Symmetric.toNonce(await fromBase64(privateKeyRecoveryNonce))
  )

  if (privateKey.isErr()) {
    return mapErr(privateKey, 'Could not decrypt private key when recovering')
  }

  return ok(await toBase64(privateKey.value))
}

export const encryptRecoveryKey = async (recoveryKey: string, passwordKey: string) => {
  const recoveryKeyEncryptedNonce = await Symmetric.generateNonce()

  const recoveryKeyEncrypted = await Symmetric.encrypt(
    await fromBase64(recoveryKey),
    Symmetric.toKey(await fromBase64(passwordKey)),
    recoveryKeyEncryptedNonce
  )

  if (recoveryKeyEncrypted.isErr()) {
    return mapErr(recoveryKeyEncrypted, 'Encrypt recovery key failed')
  }

  return ok({
    recoveryKeyEncryptedNonce: await toBase64(recoveryKeyEncryptedNonce),
    recoveryKeyEncrypted: await toBase64(recoveryKeyEncrypted.value),
  })
}

export const decryptRecoveryKey = async (
  recoveryKeyEncrypted: string,
  recoveryKeyEncryptedNonce: string,
  passwordKey: string
) => {
  const recoveryKey = await Symmetric.decrypt(
    Symmetric.toCiphertext(await fromBase64(recoveryKeyEncrypted)),
    Symmetric.toKey(await fromBase64(passwordKey)),
    Symmetric.toNonce(await fromBase64(recoveryKeyEncryptedNonce))
  )

  if (recoveryKey.isErr()) {
    return mapErr(recoveryKey, 'Could not decrypt recovery key')
  }

  return ok(await toBase64(recoveryKey.value))
}

export const decryptPrivateKey = async (
  privateKeyEncrypted: string,
  nonce: string,
  passwordKey: string
) => {
  const payload = await decryptRecoveryKey(privateKeyEncrypted, nonce, passwordKey)
  if (payload.isErr()) {
    return mapErr(payload, 'Could not decrypt private key')
  }

  return ok(payload.value)
}

export const showRecoveryKey = async (
  recoveryKeyEncrypted: string,
  recoveryKeyEncryptedNonce: string,
  passwordKey: string
) => {
  const payload = await decryptRecoveryKey(
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    passwordKey
  )

  if (payload.isErr()) {
    return mapErr(payload, 'Could not show recovery key')
  }

  return ok(await serializeRecoveryKey(await fromBase64(payload.value)))
}

export const createSystemRecoveryKey = async (recoveryKey: string, systemPbk: string) => {
  const systemRecoveryKey = await Asymmetric.encrypt(
    await deserializeRecoveryKey(recoveryKey),
    Asymmetric.toPublicKey(await fromBase64(systemPbk))
  )

  if (systemRecoveryKey.isErr()) {
    return mapErr(systemRecoveryKey, 'Could not create system recovery key')
  }

  return ok(await toBase64(systemRecoveryKey.value))
}

export const readSystemRecoveryKey = async (
  systemRecoveryKey: string,
  systemPbk: string,
  systemPrk: string
) => {
  const recoveryKey = await Asymmetric.decrypt(
    Asymmetric.toCiphertext(await fromBase64(systemRecoveryKey)),
    Asymmetric.toPublicKey(await fromBase64(systemPbk)),
    Asymmetric.toPrivateKey(await fromBase64(systemPrk))
  )

  if (recoveryKey.isErr()) {
    return mapErr(recoveryKey, 'Could not read system recovery key')
  }

  return ok(await serializeRecoveryKey(recoveryKey.value))
}

export const createRecoveryKey = async (
  privateKeyEncrypted: string,
  privateKeyEncryptedNonce: string,
  passwordKey: string,
  recoveryKeyGenerated?: string
) => {
  const recoveryKey = recoveryKeyGenerated
    ? await deserializeRecoveryKey(recoveryKeyGenerated)
    : await Random.generateBytes(RECOVERY_KEY_LENGTH)

  const privateKeyRecoveryNonce = await Symmetric.generateNonce()
  const encryptionKey = await convertRecoveryKeyToEncryptionKey(recoveryKey)
  if (encryptionKey.isErr()) {
    return mapErr(encryptionKey, 'Could not convert recovery key to encryption key')
  }

  const privateKey = await Symmetric.decrypt(
    Symmetric.toCiphertext(await fromBase64(privateKeyEncrypted)),
    Symmetric.toKey(await fromBase64(passwordKey)),
    Symmetric.toNonce(await fromBase64(privateKeyEncryptedNonce))
  )

  if (privateKey.isErr()) {
    return mapErr(privateKey, 'Could not decrypt private key when creating recovery key')
  }

  const privateKeyRecovery = await Symmetric.encrypt(
    privateKey.value,
    Symmetric.toKey(await fromBase64(encryptionKey.value)),
    privateKeyRecoveryNonce
  )

  if (privateKeyRecovery.isErr()) {
    return mapErr(privateKeyRecovery, 'Could not encrypt private key recovery')
  }

  const payload = await encryptRecoveryKey(await toBase64(recoveryKey), passwordKey)

  if (payload.isErr()) {
    return mapErr(payload, 'Couldnt encrypt recovery key')
  }

  const { recoveryKeyEncrypted, recoveryKeyEncryptedNonce } = payload.value
  const recoveryKeyPlain = await serializeRecoveryKey(recoveryKey)

  return ok({
    privateKeyRecovery: recoveryKeyGenerated ? null : await toBase64(privateKeyRecovery.value),
    privateKeyRecoveryNonce: recoveryKeyGenerated ? null : await toBase64(privateKeyRecoveryNonce),
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    recoveryKeyPlain,
  })
}

export const serializeRecoveryKey = async (recoveryKey: Uint8Array) => {
  return serializePin(await toHex(recoveryKey))
}

export const deserializeRecoveryKey = async (recoveryKey: string) => {
  return fromHex(deserializePin(recoveryKey))
}

const convertRecoveryKeyToEncryptionKey = async (recoveryKey: Uint8Array) => {
  const buffer = new ArrayBuffer(RECOVERY_KEY_LENGTH * 2)
  const encryptionKey = new Uint8Array(buffer)
  encryptionKey.set(recoveryKey, 0)
  encryptionKey.set(recoveryKey, RECOVERY_KEY_LENGTH)

  const derivedKey = await KeyDerivation.deriveKey(
    RECOVERY_KEY_LENGTH * 2,
    KeyDerivation.toSubkeyId(1),
    RECOVERY_KEY_CONTEXT,
    KeyDerivation.toKey(encryptionKey)
  )

  if (derivedKey.isErr()) {
    return mapErr(derivedKey, 'Could not derive encryption key from recovery key')
  }

  return ok(await toBase64(derivedKey.value))
}
