import { useCallback, useContext } from 'react'
import { LayoutContext, Theme } from '../Contexts/LayoutContext'
import { Language } from '../__generated__/globalTypes'

const EN_SUPPORT_URL = 'https://support.faceup.com/en/how-to-ensure-security-when-sending-reports'

const CZ_SUPPORT_URL =
  'https://support.faceup.com/cs/jak-zajistit-bezpecnost-pri-odesilani-oznameni'

const useConfigForProject = () => {
  const { theme } = useContext(LayoutContext)
  const isNntb = theme === Theme.NNTB

  const getSupportUrlByLanguage = useCallback((language: Language) => {
    switch (language) {
      case Language.Cs:
      case Language.Sk:
        return CZ_SUPPORT_URL
      default:
        return EN_SUPPORT_URL
    }
  }, [])

  const isSk = window.location.host.endsWith('nntb.sk')

  return { getSupportUrlByLanguage, isNntb, isSk }
}

export default useConfigForProject
