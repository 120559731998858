import { LanguageDropdown as LocalizationLanguageDropdown } from '@faceup/localization'
import { type ForwardedRef, forwardRef } from 'react'
import useManageLanguageDropdown, {
  type UseManageLanguageDropdownProps,
} from '../../hooks/useManageLanguageDropdown'

type LanguageDropdownProps = UseManageLanguageDropdownProps

const LanguageDropdown = forwardRef(
  (
    { availableLanguages, defaultLanguage }: LanguageDropdownProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { alphabeticallySortedLanguages, defaultSelectedLanguage, changeLanguage } =
      useManageLanguageDropdown({ availableLanguages, defaultLanguage })

    return (
      <LocalizationLanguageDropdown
        ref={ref}
        activeLanguage={defaultSelectedLanguage}
        languages={alphabeticallySortedLanguages}
        onChangeLanguage={changeLanguage}
        areLanguagesNative
        styles={{
          targetText: {
            fw: 700,
          },
        }}
      />
    )
  }
)

export default LanguageDropdown
