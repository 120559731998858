import { sharedMessages } from '@faceup/localization'
import {
  type Attachment,
  ChatDropzone,
  InputFake,
  type MentionProps,
  MentionsInput,
} from '@faceup/ui'
import { Input } from '@faceup/ui-base'
import type { MutableRefObject, ReactNode } from 'react'
import { defineMessages, useIntl } from '../TypedIntl'

const messages = defineMessages({
  addAttachments: 'Shared.report.attachments.add',
  attachmentsTitle: 'Shared.report.attachments',
})

type ChatInputProps = {
  attachments: {
    attachments: Attachment[]
    setAttachments: (attachments: Attachment[]) => void
    hookAttachmentsToDelete: [string[], (attachmentId: string) => void] | null
  } | null
  value: string
  onChange: (value: string) => void
  placeholder: string
  disabled: boolean
  inputRef: MutableRefObject<HTMLTextAreaElement | null> | null
  suffix?: ReactNode
  prefix?: ReactNode
  // biome-ignore lint/suspicious/noExplicitAny:
  mentions: (() => MentionProps<any>)[] | null
}

export const ChatInput = ({
  attachments,
  value,
  onChange,
  placeholder,
  disabled,
  inputRef,
  suffix,
  mentions,
  prefix,
}: ChatInputProps) => {
  if (mentions && mentions.length > 0) {
    if (attachments) {
      return (
        <ChatDropzoneWrapper
          attachments={attachments}
          suffix={suffix}
          prefix={prefix}
          disabled={disabled}
        >
          <ChatMentions
            inputRef={inputRef}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            mentions={mentions}
            disabled={disabled}
          />
        </ChatDropzoneWrapper>
      )
    }

    return (
      <InputFake
        value={
          <ChatMentions
            inputRef={inputRef}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            mentions={mentions}
            disabled={disabled}
          />
        }
        suffix={suffix}
        prefix={prefix}
        variant='textarea'
      />
    )
  }

  if (attachments) {
    return (
      <ChatDropzoneWrapper attachments={attachments} suffix={suffix} disabled={disabled}>
        <Input.TextArea
          ref={inputRef}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          autoSize
          variant='borderless'
          data-cy='chat-input'
          disabled={disabled}
        />
      </ChatDropzoneWrapper>
    )
  }
  if (suffix) {
    // For consistency there should be a suffix prop here, but it's not implemented
    // And no suffix is used in this case, so we don't mind
    // Textarea is only used in follow-up for basic institutions/free schools (2024-08-08)
    console.error('Suffix not implemented')
  }
  return (
    <Input.TextArea
      ref={inputRef}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      autoSize
      data-cy='chat-input'
      disabled={disabled}
    />
  )
}

type ChatDropzoneWrapperProps = {
  children: ReactNode
  attachments: NonNullable<ChatInputProps['attachments']>
} & Pick<ChatInputProps, 'suffix' | 'prefix' | 'disabled'>

const ChatDropzoneWrapper = ({
  children,
  attachments,
  suffix,
  prefix,
  disabled,
}: ChatDropzoneWrapperProps) => {
  const { formatMessage } = useIntl()

  return (
    <ChatDropzone
      attachments={attachments.attachments}
      setAttachments={attachments.setAttachments}
      suffix={suffix}
      prefix={prefix}
      translations={{
        tooManyFiles: formatMessage(sharedMessages.attachmentsTooManyFiles),
        tooLargeFile: formatMessage(sharedMessages.attachmentsTooLargeFile),
        metadataRemoved: formatMessage(sharedMessages.metadataRemoved),
        metadataUnableToRemove: formatMessage(sharedMessages.metadataUnableToRemove),
        unsupportedMimetype: formatMessage(sharedMessages.unsupportedMimetype),
        delete: formatMessage(sharedMessages.delete),
        add: formatMessage(messages.addAttachments),
        title: formatMessage(messages.attachmentsTitle),
      }}
      disabled={disabled}
    >
      {children}
    </ChatDropzone>
  )
}

type ChatMentionsProps = {
  mentions: NonNullable<ChatInputProps['mentions']>
} & Pick<ChatInputProps, 'inputRef' | 'value' | 'onChange' | 'placeholder' | 'disabled'>

const ChatMentions = ({
  inputRef,
  value,
  onChange,
  placeholder,
  disabled,
  mentions,
}: ChatMentionsProps) => (
  <MentionsInput
    inputRef={inputRef}
    value={value}
    onChange={(_, newText) => {
      onChange(newText)
    }}
    allowSuggestionsAboveCursor
    placeholder={placeholder}
    autoFocus={false}
    data-cy='chat-input'
    disabled={disabled}
  >
    {mentions.map((mention, index) => (
      // biome-ignore lint/suspicious/noArrayIndexKey:
      <MentionsInput.Mention key={index} {...mention()} />
    ))}
  </MentionsInput>
)
