import { gql } from '@apollo/client'
import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { Empty, Typography } from '@faceup/ui-base'
import { ReportCreationOrigin } from '@faceup/utils'
import { useMantineTheme } from '@mantine/core'
import { useContext } from 'react'
import { FormattedMessage as FormattedMessageDef } from 'react-intl'
import type { CompanyChat_report } from '../../__generated__/globalTypes'
import { useMarkMessagesAsRead } from '../../hooks/useMarkMessagesAsRead'
import FollowUpChatMessage, { FollowUpChatMessageFragments } from './FollowUpChatMessage'
import { RelativeMessagesContext } from './RelativeMessagesContext'
import { ucEmptyChat } from './customIcons/ucEmptyChat'

const { Text } = Typography

export const fragments = {
  CompanyChat_report: gql`
    fragment CompanyChat_report on CompanyReport {
      status
      isDemo
      creationOrigin
      victim {
        id
      }
      followUpActivities(page: 0, rowsPerPage: 10000) {
        items {
          ...FollowUpChatMessage_followUpActivityNode
        }
      }
    }

    ${FollowUpChatMessageFragments.FollowUpChatMessage_followUpActivityNode}
  `,
}

type Props = {
  report: CompanyChat_report | null
  subscribeToNewMessages?: () => (() => void) | null
  onMessageSeen?: () => void
  userId: string
  fileDownloadHandler?: (blob: Blob, name: string) => Promise<void>
}

const Chat = ({ report, userId, fileDownloadHandler, onMessageSeen }: Props) => {
  const theme = useMantineTheme()
  const relativeMessages = useContext(RelativeMessagesContext)
  const followUpActivities = report?.followUpActivities?.items ?? []

  const isFollowUp = relativeMessages.type === 'Victim'

  useMarkMessagesAsRead({
    unreadMessage: followUpActivities.filter(
      message =>
        // filter all messages, which are not created by myself
        message?.author?.id !== userId &&
        // in akutan, filter messages, which are not already read by myself
        ((!message?.readByMemberIds?.some(m => m === userId) && !isFollowUp) ||
          // in followup, filter messages, which are not already read by myself
          (!message?.isMessageReadByVictim && isFollowUp))
    )?.[0], // get only the first message, because we mark them as read in the whole report
    onCompleted: () => onMessageSeen?.(),
  })

  return (
    <>
      {followUpActivities.length === 0 && (
        <EmptyWrapper>
          <Empty
            image={
              <UntitledIcon
                icon={ucEmptyChat}
                fontSize={100}
                color={theme.colors['primary']?.[7]}
              />
            }
            description={
              <Text style={{ color: '#688699' }}>
                <FormattedMessageDef {...relativeMessages.empty} />
              </Text>
            }
          />
        </EmptyWrapper>
      )}
      {((report?.creationOrigin &&
        [ReportCreationOrigin.Reporter, ReportCreationOrigin.Redaction].includes(
          report.creationOrigin
        )) ||
        report?.isDemo) &&
        followUpActivities.map(node => (
          <FollowUpChatMessage
            orientation={node?.author?.id === userId ? 'right' : 'left'}
            key={node?.id}
            message={node}
            fileDownloadHandler={fileDownloadHandler}
          />
        ))}
    </>
  )
}

const EmptyWrapper = styled.div`
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Chat
