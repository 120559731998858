import { gql, useQuery } from '@apollo/client'
import type { ReactNode } from 'react'
import { FeatureFlagContext } from './FeatureFlagContext'

type Props = {
  children: ReactNode
}

const query = {
  FeatureFlagQuery: gql`
    query FeatureFlagQuery {
      systemInfo {
        id
        featureFlags {
          id
          application
        }
      }
    }
  `,
}

// we don't generate types in libs, thus response type has to be specified
type FeatureFlagQuery = {
  systemInfo: {
    __typename: 'System'
    id: string
    featureFlags: {
      __typename: 'FeatureFlags'
      id: string
      application: boolean | null
    } | null
  } | null
}

const FeatureFlagProvider = ({ children }: Props) => {
  const { data, loading } = useQuery<FeatureFlagQuery>(query.FeatureFlagQuery, {
    onError: error => {
      console.error(error)
    },
  })

  const featureFlags = data?.systemInfo?.featureFlags ?? null

  return (
    <FeatureFlagContext.Provider
      value={{
        loading: loading && !data,
        application: featureFlags?.application ?? false,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

export default FeatureFlagProvider
