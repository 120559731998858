import { Typography } from '@faceup/ui-base'
import { Box, Flex, MantineProvider, Stack, Text, useMantineTheme } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import merge from 'deepmerge'
import { type ReactNode, useMemo } from 'react'
import { MoreInfo } from '../IconWithLogic'

export type FormItemProps = {
  children: ReactNode
  label?: ReactNode
  description?: ReactNode
  hint?: ReactNode
  secondary?: ReactNode
  withAsterisk?: boolean
  errorMessage?: ReactNode
  beforeInput?: ReactNode
  afterInput?: ReactNode
  withoutWrapper?: boolean
  variant?: 'gray' | 'black'
  forceZeroMinHeightForErrorMessage?: boolean
}

export const FormItem = ({
  children,
  label,
  hint,
  description,
  secondary,
  withAsterisk,
  errorMessage,
  beforeInput,
  afterInput,
  variant = 'gray',
  withoutWrapper = false,
  forceZeroMinHeightForErrorMessage = false,
  ...props
}: FormItemProps) => {
  const theme = useMantineTheme()
  const { ref: secondarySizeRef, width: secondaryWidth } = useElementSize()
  const mergedTheme = useMemo(
    () =>
      merge(theme, {
        components: {
          Input: {
            styles: {
              input: {
                borderColor: errorMessage ? theme.colors.red[7] : undefined,
                color: errorMessage ? theme.colors.red[7] : undefined,
                '&:focus': {
                  borderColor: errorMessage ? theme.colors.red[7] : undefined,
                },
              },
            },
          },
          InputWrapper: {
            styles: {
              root: {
                paddingBlockEnd: '0',
              },
            },
          },
        },
      }),
    [theme, errorMessage]
  )

  if (withoutWrapper) {
    return (
      <MantineProvider inherit theme={mergedTheme}>
        {children}
      </MantineProvider>
    )
  }

  const isSomethingInLabelRow = label || hint || secondary

  return (
    <Box
      {...props}
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      {secondary && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
          }}
          ref={secondarySizeRef}
        >
          {secondary}
        </Box>
      )}
      <Box component='label'>
        <Stack spacing={isSomethingInLabelRow ? 4 : 0}>
          <Box sx={{ width: `calc(100% - ${secondaryWidth}px)` }}>
            {variant === 'gray' ? (
              <Text c='#688699' fw={600}>
                {label}{' '}
                {withAsterisk && (
                  <Text c='red' component='span' fz={14} ff='SimSun,sans-serif'>
                    *
                  </Text>
                )}{' '}
                {hint && <MoreInfo title={hint} />}{' '}
              </Text>
            ) : (
              <Typography.Title level={5}>
                {label} {withAsterisk && <span style={{ color: 'red' }}>*</span>}{' '}
                {hint && <MoreInfo title={hint} />}{' '}
              </Typography.Title>
            )}
          </Box>
          {description && <Typography.Text type='secondary'>{description}</Typography.Text>}
          <Flex
            align='center'
            gap='8px'
            sx={{
              marginTop: variant === 'black' && !description ? '16px' : undefined,
            }}
          >
            {beforeInput && <div>{beforeInput}</div>}
            <Box sx={{ flex: '1 1 auto' }}>
              <MantineProvider inherit theme={mergedTheme}>
                {children}
              </MantineProvider>
            </Box>
            {afterInput && <div>{afterInput}</div>}
          </Flex>
        </Stack>
      </Box>
      <Box
        sx={{
          minHeight: forceZeroMinHeightForErrorMessage ? 0 : '22px',
        }}
      >
        <Text c='red'>{errorMessage}</Text>
      </Box>
    </Box>
  )
}
