import type { Branded } from '@faceup/utils'
import { ok } from 'neverthrow'
import { createErr, getSodium } from '../utils/general'
import { Random } from './random'

export type KeyDerivationSubkeyId = Branded<number, 'KeyDerivationSubkeyId'>
export type KeyDerivationContext = Branded<string, 'KeyDerivationContext'>
export type KeyDerivationKey = Branded<Uint8Array, 'KeyDerivationKey'>
export type KeyDerivationSubkey = Branded<Uint8Array, 'KeyDerivationSubkey'>

export class KeyDerivation {
  public static async deriveKey(
    length: number,
    subKeyId: KeyDerivationSubkeyId,
    context: KeyDerivationContext,
    key: KeyDerivationKey
  ) {
    const sodium = await getSodium()
    try {
      const subKey = sodium.crypto_kdf_derive_from_key(length, subKeyId, context, key)

      return ok(KeyDerivation.toSubkey(subKey))
    } catch (e) {
      return createErr('Could not derive key', e)
    }
  }

  public static async generateSubkeyId() {
    return KeyDerivation.toSubkeyId(await Random.generateInt(255))
  }

  public static toSubkeyId(subKeyId: number) {
    return subKeyId as KeyDerivationSubkeyId
  }

  public static toContext(context: string) {
    return context as KeyDerivationContext
  }

  public static toKey(key: Uint8Array) {
    return key as KeyDerivationKey
  }

  public static toSubkey(subkey: Uint8Array) {
    return subkey as KeyDerivationSubkey
  }
}
